export const AdminLargeReportDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ADMIN_DOWNLOAD_DATA':
      return {
        ...state,
        APIResponse: action.payload,
      };

    case 'API_START':
      if (action.payload === 'FETCH_ADMIN_DOWNLOAD_DATA') {
        return {
          ...state,
          APIResponse: '',
          isFetchingAdminLargeReportDownload: true,
        };
      }
      break;

    case 'API_END':
      if (action.payload === 'FETCH_ADMIN_DOWNLOAD_DATA') {
        return {
          ...state,
          isFetchingAdminLargeReportDownload: false,
        };
      }
      break;
    default:
      return state;
  }
  return state;
};
