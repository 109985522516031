
import apiAction from 'common/redux/actions/apiAction';
import {
  POST_ADMIN_LARGE_DOWNLOAD_REPORT_URL,
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getAdminDownload = data => {
  return {
    type: 'GET_ADMIN_DOWNLOAD_DATA',
    payload: data,
  };
};

export const AdminDownloadData = configParams => {
  return apiAction.postAPI({
    url: POST_ADMIN_LARGE_DOWNLOAD_REPORT_URL(configParams.reportType),
    data: configParams,
    onSuccess: getAdminDownload,
    onFailure: err => logError('Error occured in downloading Admin data.:', err),
    label: 'FETCH_ADMIN_DOWNLOAD_DATA',
  });
};
