import { Autocomplete, Box, Skeleton, Grid, TextField, Typography, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import {  DropDownIcon, ToolTipWrapper } from 'common/components';
import { FilterManagerSingleTeamDataWWID, ManagerSingleTeamDetails } from 'TAP/redux/actions';
import React, { useCallback, useEffect, useState } from 'react';
import { RedTheme, SelectedTheme } from 'common/global';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import GenericPopup from 'common/popup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { ManagerSingleTeamColumnConfig } from 'TAP/pages';
import { TapGlobalMessages } from 'TAP/global';
import { userRole } from 'TAP/styles/genericStyles/userRole';
import { percent } from 'TAP/styles/genericStyles/percentage';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights'; 
import { height } from 'TAP/styles/genericStyles/height'; 
import { width } from 'TAP/styles/genericStyles/width';
import { fontsize } from 'TAP/styles/genericStyles/fontSize';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES_ARRAY} from 'common/global/constants';
import Button from '@mui/material/Button';
import { LeaveIndicator,ComplianceDonutChartTooltipBody } from 'TAP/components';
import { generic } from 'TAP/styles/genericStyles/Generic';
import { getTextColorForComplianceTable , getTextColor } from 'TAP/utils/commonMethods';
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';
export const ManagerSingleTeamTable = () => {
  const theme = SelectedTheme();
  let redtheme = RedTheme;
  const [managerSingleTeamAPIError, setManagerSingleTeamAPIError] = useState('');
  const [selectedWWID, setSelectedWWID] = useState(null);
  const [selectedWWIDOptions, setSelectedWWIDOptions] = useState([]);
  //Filters values
  const [selectedWWIDValue, setSelectedWWIDValue] = useState(null);

  /**Generic Popup */
  const [openPopup, setOpenPopup] = useState(false);
  const [popupHeader, setPopupHeader] = useState('');
  const [popupButtons, setPopupButtons] = useState([]);
  const [childPopUpParams, setChildPopUpParams] = useState({});
  const [childPopUpColumns, setChildPopUpColumns] = useState([]);
  const [popUserId, setPopUserId] = useState('');
  const [popupStatus, setPopupStatus] = useState('');
  const [autocompleteConfigs, setAutocompleteConfigs] = useState([]);
  const [noTrainingsAssigned, setNoTrainingsAssigned] = useState(false);
  const [isCheckboxSelection, setIsCheckboxSelection] = useState(false);
  const [selectedStatusView, setSelectedStatusView] = useState('');

  const statusMapper = {
    'Job Description': 'jobDescription',
    'Summary of Experience': 'summaryOfExperience',
    'Individual Training Plan': 'individualTrainingPlan',
    'Trainings Completed On Time': 'directReportTrainings/completeOnTime',
    'Training Completed Overall' : 'directReportTrainings/completedOverall',
    'Inspection Readiness' : 'inspectionReadiness',
  };
  const dispatch = useDispatch();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    teamMemberName: '',
    transcriptDate: [],
    sortField: 'full_name',
    orderBy: 'ASC',
    selectedStatus : '',
  });

  /** Tab Panel Note- Observation Grid and date range will not work in panel */
  const [userId, setUserId] = useState('');
  const [userDetails, setuserDetails] = useState({});
  const [sortingTooltip,setSortingTooltip] = useState('')

  const CLICK_TO_SORT_ASCENDING = 'Click to sort ascending';
  const CLICK_TO_SORT_DESCENDING = 'Click to sort descending';
  const screen = useScreen(); 

  let loggedInUserDetails = useSelector(state => state.tapUserDetailsRd);

  useEffect(() => {
    setSortingTooltip(CLICK_TO_SORT_ASCENDING);

    if (loggedInUserDetails && Object.keys(loggedInUserDetails).length) {
      setUserId(loggedInUserDetails?.userDetails?.data[0].user_id);
      setuserDetails(loggedInUserDetails?.userDetails?.data[0]);
    }
  }, [loggedInUserDetails]);

  let managerSingleTeamStateData = useSelector(state => state.tapManagerSingleTeamReducerRd);

  const getWWIDFromSearch = selectWWIDValue => {
    if (selectWWIDValue && typeof selectWWIDValue === 'object') {
      return selectWWIDValue.id;
    }
  };

  const handleSelectStatusChange = event => {
    if (event.target.value === 'All' || event.target.value.length === 0) {
      setPageState(old => ({
        ...old,
        
        selectedStatus: '',
      }));
      setSelectedStatusView('All');
    } else {
      setPageState(old => ({
        ...old,
        selectedStatus: event.target.value,
      }));
      setSelectedStatusView(event.target.value);
    }
  };

  useEffect(() => {
    if (userId ) {
      setPageState(old => ({ ...old, isLoading: true }));
      const payloadUserLogedIn = {
        sortField: pageState.sortField,
        orderBy: pageState.orderBy,
        userId: userId,
        page: pageState.page,
        limit: pageState.pageSize,
        teamMember: getWWIDFromSearch(selectedWWIDValue),
        status: pageState.selectedStatus,
        apiType: getWWIDFromSearch(selectedWWIDValue) ? 'SEARCH' : 'LOAD', // LOAD,DRILL,SUGGESTION,SEARCH
        isDirectReportee: userDetails?.user_role === userRole.usr3 ? true : false,
      };
      dispatch(ManagerSingleTeamDetails(payloadUserLogedIn));
    }
  }, [
    userId,
    pageState.page,
    pageState.pageSize,
    pageState.teamMemberName,
    pageState.transcriptDate,
    pageState.selectedStatus,
    selectedWWIDValue,
    pageState.sortField,
    pageState.orderBy,
  ]);

  const setTableData = () => {
    setPageState(old => ({
      ...old,
      isLoading: false,
      data: managerSingleTeamStateData?.managerSingleTeamAPIResponse?.data?.records,
    }));
  }

  const getClassName = (flag,index, trainingAssigned) => {
    //This method is used for making the LOA rows dark grey color and to make odd rows light grey color
    if(flag === 'Y' || trainingAssigned === 0) {
      return 'manager-many-teams-table-row-disabled';  
    }
    return index%generic.gen2 === 1 ? 'manager-many-teams-table-row-light': 'manager-many-teams-table-row-dark'; 
  }

  const processManagerSingleTeamData = () => {
    setTableData(); 
    setNoTrainingsAssigned(false);
    if (managerSingleTeamStateData?.managerSingleTeamAPIResponse?.data?.records?.length === 0){
      setManagerSingleTeamAPIError('No trainings found for the filters applied');
    }
  }

  const processManagerSingleTeamNoData = () => {
    if (managerSingleTeamStateData?.managerSingleTeamAPIResponse?.data?.records?.length === 0) {
      setTableData(); 
      setNoTrainingsAssigned(true);    
    } else {
      setManagerSingleTeamAPIError('No trainings found for the filters applied')
    }
  }


  useEffect(() => {
    if (managerSingleTeamStateData) {
      if (managerSingleTeamStateData?.managerSingleTeamAPIResponse?.data?.records) {
         processManagerSingleTeamData();  
      } else {
        processManagerSingleTeamNoData()
      }
    }
  }, [managerSingleTeamStateData]);

  const columns = [
    {
      field: 'full_name',
      flex: 1,
      width : width.wid400,
      minWidth : width.wid150,
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <Box>
            <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: fontWeightStyles.fw5.fontWeight,
                fontSize: '16px',
                lineHeight: '18px',
                fontFamily: '"JohnsonText-Medium"',
                color: redtheme.palette.fontColor.black_gray,
                width : width.wid350, 
              }}
            >
              {params.value}
            </Typography>
            
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: fontWeightStyles.fw5.fontWeight,
                fontSize: '16px',
                lineHeight: '18px',
                fontFamily: '"JohnsonText-Light"',
                color: redtheme.palette.fontColor.dark_grayish_orange,
                marginRight : '50px',
              }}
            >
              {params.id}
            </Typography>
            </Grid>
            <Grid item xs={6}>
              {params.row.leave_of_absence === 'Y' ? 
                <LeaveIndicator />
              :
              null}
            </Grid>
            </Grid>
            
          </Box>
        );
      },
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>
            {userDetails?.user_role === userRole.usr3 ? 'Users' : 'Team members'}
          </Typography>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              marginLeft: userDetails?.user_role === userRole.usr3 ? 0 : '25px',
              color: redtheme.palette.fontColor.gray,
            }}
          >
            WWID
          </Typography>
        </Box>
      ),
    },
    {
      field: 'jobDescription',
      headerName: 'Job Description',
      width: width.wid180,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
           <ToolTipWrapper  title = {TapGlobalMessages.columnHeaderTooltip.jobDescriptionTooltip} placement='top'>
        <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Job Description</Typography>
       </ToolTipWrapper>
      </Box>
      ),
      
      renderCell: params => {
        const newcolor = getTextColorForComplianceTable(params?.row?.jobDescription_Flag);
        return (
          params?.row?.leave_of_absence === 'Y' ? 
          <Typography data-testid='blank-jobDescription' sx={{color:redtheme.palette.fontColor.gray_orange}}></Typography>
          :
          <Typography
            sx={{
              fontWeight: fontWeightStyles.fw7.fontWeight,
              fontSize: '16px',
              lineHeight: '18px',
              fontFamily: '"JohnsonText-Bold"',
               cursor: 'auto',
              color: newcolor,
            }}
          >
            {' '}
            {params.value}
          </Typography>
          
        );
      },
    },
    {
      field: 'summaryOfExperience',
      headerName: 'Summary of Experience',
      width: width.wid180,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
          <ToolTipWrapper  title = {TapGlobalMessages.columnHeaderTooltip.summaryOfExpTooltip} placement='top'>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Summary of</Typography>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"', marginLeft: '5px' }}>
            Experience
          </Typography>
          </ToolTipWrapper>
        </Box>
      ),
      renderCell: params => {
        const newcolor = getTextColorForComplianceTable(params?.row?.summaryOfExperience_Flag);
        return (
          params?.row?.leave_of_absence === 'Y' ? 
          <Typography data-testid='blank-summaryOfExperience' sx={{color:redtheme.palette.fontColor.gray_orange}}></Typography>
          :
          <Typography
            sx={{
              fontWeight: fontWeightStyles.fw7.fontWeight,
              fontSize: '16px',
              lineHeight: '18px',
              fontFamily: '"JohnsonText-Bold"',
              cursor: 'auto',
              color: newcolor,
            }}
          >
            {' '}
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'individualTrainingPlan',
      width: width.wid180,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
           <ToolTipWrapper  title = {TapGlobalMessages.columnHeaderTooltip.IndiviTrainingPlanTooltip} placement='top'>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Individual Training</Typography>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"', marginLeft: '50px' }}>
            Plan
          </Typography>
          </ToolTipWrapper>
        </Box>
        ),
      renderCell: params => {
        const newcolor = getTextColorForComplianceTable(params?.row?.individualTrainingPlan_Flag);
        return (
          params?.row?.leave_of_absence === 'Y' ? 
          <Typography data-testid='blank-individualTrainingPlan' sx={{color:redtheme.palette.fontColor.gray_orange}}></Typography>
          :
          <Typography
            sx={{
              fontWeight: fontWeightStyles.fw7.fontWeight,
              fontSize: '16px',
              lineHeight: '18px',
              fontFamily: '"JohnsonText-Bold"',
              cursor:'auto',
              color: newcolor,
            }}
          >
            {' '}
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'training_completed_on_time_percent',
      width: width.wid250,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
          <ToolTipWrapper   title = {TapGlobalMessages.columnHeaderTooltip.completedOnTimeTooltip} placement='top'>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Training Completed</Typography>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"', marginLeft: '40px' }}>
            on Time
          </Typography>
          </ToolTipWrapper>
        </Box>
      ),
      renderCell: params => {
        const completion_on_time_color = getTextColor(params?.row?.training_completed_on_time_percent);
        return (
          params?.row?.leave_of_absence === 'Y' || params?.row?.trainingAssigned === 0 ? 
          <Typography data-testid='blank_training_completed_onTime_percent' sx={{color:redtheme.palette.fontColor.gray_orange}}></Typography>
          :
          <Typography
            sx={{
              fontWeight: fontWeightStyles.fw7.fontWeight,
              fontSize: '16px',
              lineHeight: '18px',
              fontFamily: '"JohnsonText-Bold"',
              cursor: params.value ? 'pointer' : 'not-allowed',
              color: completion_on_time_color,
            }}
            onClick={() =>handlePopupButtonClick(params, 'Trainings Completed On Time', false)}
          >
            {' '}
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'training_completed_overall_percent',
      width: width.wid250,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
          <ToolTipWrapper title = {TapGlobalMessages.columnHeaderTooltip.completedOverallTooltip} placement='top'>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Training Completed</Typography>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"', marginLeft: '40px' }}>
            Overall
          </Typography>
          </ToolTipWrapper>
        </Box>
      ),
      renderCell: params => {
        
        const completion_overall_color = getTextColor(params?.row?.training_completed_overall_percent);
        return (
          params?.row?.leave_of_absence === 'Y' || params?.row?.trainingAssigned === 0 ? 
          <Typography data-testid='blank_training_completed_overall_percent' sx={{color:redtheme.palette.fontColor.gray_orange}}></Typography>
          :
          <Typography
            sx={{
              fontWeight: fontWeightStyles.fw7.fontWeight,
              fontSize: '16px',
              lineHeight: '18px',
              fontFamily: '"JohnsonText-Bold"',
              cursor: params.value ? 'pointer' : 'not-allowed',
              color:completion_overall_color,
            }}
            onClick={() =>
              params.value
                ? handlePopupButtonClick(params, 'Training Completed Overall', false)
                : ''
            }
          >
            {' '}
            {params.value}
          </Typography>
        );
      },
    },
  
    {
      field: 'inspectionReadiness_Indicator',
      width: width.wid200,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box>
           <ToolTipWrapper title = {<ComplianceDonutChartTooltipBody/>} placement='top'>  
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Compliance</Typography>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"', marginLeft: '20px' }}>
            Profile
          </Typography>
          </ToolTipWrapper>
        </Box>
      ),
      
      renderCell: params => {
   
        let backgroundcolor;
        if (params.row.inspectionReadiness_Indicator === 'G') {
          backgroundcolor = redtheme.palette.dataGrid.light_moderate_lime_green;
        } else if (params.row.inspectionReadiness_Indicator === 'Y') {
          backgroundcolor = redtheme.palette.dataGrid.yellow;
        }
        else {
          backgroundcolor = redtheme.palette.dataGrid.red;
        }
        
        let new_text;
        if (params.row.inspectionReadiness_Indicator === 'G') {
          new_text = 'Compliant';
        } else if (params.row.inspectionReadiness_Indicator === 'Y') {
          new_text = 'At Risk';
        }
        else {
          new_text = 'Non-Com.';
        }

        return (
          params?.row?.leave_of_absence === 'Y' ?
          <Button sx={{ fontFamily: '"JohnsonText-Bold"',
        fontSize: '10px',
        backgroundColor:redtheme.palette.fontColor.gray_orange,
        color:redtheme.palette.fontColor.gray_orange,
        borderRadius: '8px', textTransform:'none',pointerEvents: 'none',  }}
        >-</Button>
        :
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '10px',
              lineHeight: '18px',
              fontFamily: '"JohnsonText-Bold"',
              cursor: params.value ? 'pointer' : 'not-allowed',
              textTransform:'none',pointerEvents: 'none',
            }}
            
          >
            {' '}
            <Typography>
            <Button sx={{ fontFamily: '"JohnsonText-Bold"',
             backgroundColor:backgroundcolor,
             fontSize: '10px',
              color:params.row.inspectionReadiness_Indicator === 'Y'? redtheme.palette.fontColor.very_dark_grey:redtheme.palette.fontColor.white,
              borderRadius: '10px', textTransform:'none', pointerEvents: 'none' }}>{new_text}</Button>
            </Typography>
          </Typography>
        );
      },
    },
  ];

  const handleSortModelChange = useCallback(sortModel => {
    // Here you save the data you need from the sort model
    // setQueryOptions({ sortModel: [...sortModel] });
    setPageState(old => ({
      ...old,
      sortField: sortModel[0]?.field,
      orderBy: sortModel[0]?.sort,
    }));

    if(sortModel[0]?.sort.toUpperCase() === 'ASC'){
      setSortingTooltip(CLICK_TO_SORT_DESCENDING);
    }else if(sortModel[0]?.sort.toUpperCase() === 'DESC'){
      setSortingTooltip(CLICK_TO_SORT_ASCENDING);
    }
  }, []);

  const handleAutoCompleteChange = (_event, newValue, reason, type) => {
    if ((reason === 'reset' || reason === 'clear') && !newValue) {
      if (type === 'wwid') {
        setSelectedWWID(null);
      }
    } else {
      if (newValue) {
        if (type === 'wwid' && newValue.length && reason != 'reset') {
          setSelectedWWID(newValue);
        }
      } else {
        setSelectedWWID(null);
      }
    }
  };


  //Filter by WWID
  useEffect(() => {
    if (selectedWWID) {
      const payloadUserLogedIn = {
        userId: userId,
        page: 1,
        limit: 10,
        teamMember: selectedWWID,
        transcriptDate: [],
        apiType: 'SUGGESTION',
        isDirectReportee: userDetails?.user_role === userRole.usr3 ? true : false,
      };
      dispatch(FilterManagerSingleTeamDataWWID(payloadUserLogedIn));
    }
  }, [selectedWWID]);

  let filterManagerSingleTeamData = useSelector(state => state.tapManagerSingleTeamFilterRd);

  //load team members or wwid
  const setDefaultWWIDOptions = optData => {
    let wwidFilterRecords = [];
    
      if(optData && optData.length) {
        optData.forEach(item => {
          wwidFilterRecords.push({
            id: item.user_id,
            label: item.search_value,
          });
        })
      }
    setSelectedWWIDOptions(wwidFilterRecords);
  };

  //Set filter on search, paste
  useEffect(() => {
    if (filterManagerSingleTeamData && Object.keys(filterManagerSingleTeamData).length) {
      if (
        filterManagerSingleTeamData?.managerSingleTeamFilterByWWID?.data?.records &&
        filterManagerSingleTeamData?.managerSingleTeamFilterByWWID?.data?.records.length
      )
        setDefaultWWIDOptions(
          filterManagerSingleTeamData?.managerSingleTeamFilterByWWID?.data?.records,
        );
    } else {
      setDefaultWWIDOptions([]);
    }
  }, [filterManagerSingleTeamData]);

  const onChangeAutoCompleteFilter = (_event, value, reason, _details, type) => {
    if (reason === 'selectOption' && value && type === 'wwid') {
      setSelectedWWIDValue(value);
    } else if (reason === 'clear' && !value && type === 'wwid') {
      setSelectedWWIDValue([]);
      setDefaultWWIDOptions([]);
    }
    else {
      //Invalid
    }
  };

  /**Generic Popup Params */
  useEffect(() => {
    if (childPopUpParams && Object.keys(childPopUpParams).length) {
      setChildPopUpColumns(ManagerSingleTeamColumnConfig(childPopUpParams?.field));
    }
  }, [childPopUpParams]);

  const handlePopupButtonClick = (params, value, checkboxSelectionOpt) => {
    
    if(params.value === null) {
      return; 
    }
    
    setChildPopUpParams(params);
    setPopupStatus('single');
    setPopUserId(params?.id);
    setOpenPopup(true);
    // Set the desired header value
    setPopupHeader(value); 
    setIsCheckboxSelection(checkboxSelectionOpt);

    if (params?.field === 'training_completed_on_time_percent' || params?.field === 'training_completed_overall_percent') {
      setPopupButtons([
        {
          label: 'Download Excel',
        },
      ]);
    } else {
      ///empty
    }
    // Prepare dynamic autocomplete configurations
    let configs;
    if (params?.field === 'training_completed_on_time_percent' || params?.field === 'training_completed_overall_percent') {
      
      configs = [
        {
          label: 'Training Name',
          options: [],
          getOptionLabel: option => option,
          minWidth: '300px',
          mr: 2,
        },
        // Add more configurations as needed
      ];
    } else {
      configs = [
        {
          label: 'Training Name',
          options: [],
          getOptionLabel: option => option,
          minWidth: '550px',
          mr: 2,
        },
        // Add more configurations as needed
      ];
    }
    setAutocompleteConfigs(configs);
  };

  const noTraining = ()  => {
    if(noTrainingsAssigned) {
      return (
        <DataGrid
          loading={false}
          rows={[]}
          columns={columns}
          rowCount={0}
          components={{
            noRowsOverlay: customNoDataOverlay,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE } },
          }}
          pageSizeOptions={[]}
          sx={{
            border: 'none',
            '& .MuiDataGrid-columnHeaders': {
              fontSize: fontsize.fs16,
              fontFamily: '"JohnsonText-Bold"',
            },
          }}
        />
      ) 
    }
    return managerSingleTeamAPIError ? (
      <Typography
        component='h6'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          background: redtheme.palette.box.very_light_grey,
          fontWeight: '900',
          fontSize: '26px',
          color: redtheme.palette.box.dark_grayish_blue,
          width: '100%',
          height: '300px',
        }}
      >
        {managerSingleTeamAPIError}
      </Typography>
    ) : (
      <Skeleton variant='rectangular' width={percent.pr100 + '%'} height={height.h400} />
    )
    
  }

  const managerTable = () => {
    return (managerSingleTeamStateData &&
      Object.keys(managerSingleTeamStateData)?.length &&
      Object.keys(managerSingleTeamStateData?.managerSingleTeamAPIResponse?.data?.records)
        ?.length ? (
        <Box
          data-testid='managerSingleTeamRole2DataGrid'
          sx={{ width: '100%', marginBottom: '20px', minHeight: height.h100, maxHeight: height.h630 }}
        >
          <DataGrid
          MenuProps ={{PaperProps:{
              style:{
                borderRadius:'12px'
              }
          }}}
            rows={pageState.data}
            getRowClassName={record => getClassName(record.row.leave_of_absence,pageState.data.indexOf(record.row),record.row.trainingAssigned)}
            columns={columns}
            getRowId={row => row?.user_id}
            loading={pageState.isLoading}
            paginationMode='server'
            sortingMode='server'
            disableRowSelectionOnClick
            localeText={{columnHeaderSortIconLabel: sortingTooltip}}
            onSortModelChange={handleSortModelChange}
            rowCount={
              pageState?.data && pageState?.data?.length
                ? pageState.data[0]?.total_records
                : 0
            }
            initialState={{
              pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE } },
            }}
            pageSizeOptions={PAGE_SIZES_ARRAY}
            onPaginationModelChange={newPage => {
              setPageState(old => ({
                ...old,
                page: newPage.page + 1,
                pageSize: newPage.pageSize,
              }));
            }}
            onPageSizeChange={newPageSize =>
              setPageState(old => ({ ...old, pageSize: newPageSize }))
            }
            sx={{
              border: 'none',
              minHeight: height.h100,
              maxHeight: height.h630,
              '& .MuiDataGrid-cell': {
                fontSize: '14px',
                fontWeight: fontWeightStyles.fw45.fontWeight,
                fontFamily: '"J&J CircularTT Book Web"',
              },
              '& .MuiDataGrid-row:nth-of-type(odd):hover': { backgroundColor: redtheme.palette.dataGrid.very_light_grey},
              '& .MuiDataGrid-columnHeaders': {
                fontSize: fontsize.fs16,
                fontFamily: '"JohnsonText-Bold"',
              },
              '& .MuiDataGrid-row.Mui-selected': { backgroundColor:redtheme.palette.dataGrid.white},
              '& .MuiDataGrid-row.Mui-selected:hover': { backgroundColor: redtheme.palette.dataGrid.white },
              '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
                backgroundColor: 'rgba(0, 0, 153, 0.03)',
              },
              '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
                backgroundColor: redtheme.palette.dataGrid.very_light_grey,
              },
              '& .super-app.disable': {
                color: redtheme.palette.dataGrid.light_gray,
                cursor: 'not-allowed',
              },
              '& .MuiTablePagination-selectLabel': {
                fontFamily: '"JohnsonText-Medium"',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '16px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '& .MuiTablePagination-displayedRows': {
                fontFamily: '"JohnsonText-Bold"',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '16px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '& .MuiTablePagination-select': {
                fontFamily: '"JohnsonText-Bold"',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '32px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
              '&.MuiDataGrid-root .MuiDataGrid-main':{
                flexGrow: 1,
                position: 'relative',
                overflow: 'hidden',
                // width: !screen.isDesktop ? (screen.isMobile ? '35%': '65%') :{} 
                
              },
              '&.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-scrollbar':{
                overflowY :'hidden',
              },
              '&.MuiDataGrid-root .MuiDataGrid-footerContainer':{
                justifyContent: screen.resolveStyles({ mobile: 'start', tablet: 'center', desktop: 'end' })
              },
              '&.MuiDataGrid-root .MuiTablePagination-root .MuiTablePagination-input':{
               display : screen.isMobile ? 'revert' : 'block'
               
              },
              '&.MuiDataGrid-root .MuiTablePagination-root .MuiTablePagination-selectLabel':{
               display :  screen.isMobile ? 'revert' : 'block'
              },
              '&.MuiDataGrid-root .MuiTablePagination-root' :{
                overflow : 'hidden'
              }
            }}
          />
        </Box>
      ) : (
        <Box
          data-testid='nodataavailable'
          sx={{
            width: '100%',
            marginBottom: '20px',
            minHeight: height.h100,
            maxHeight: height.h630,
          }}
        >
          {noTraining()}
        </Box>
      ))
  }
  const filterdropdown = () => {
      return ( <Box sx={{ width: screen.isMobile ? '48vw' : '40%', fontFamily: '"JohnsonText-Medium"',
      marginLeft: screen.isMobile ? '20px' : NUMBER_ARRAY.zero }}>
      <FormControl
        variant='standard'
        data-testid='status_filter'
        sx={{ width: '95%', mt: 2, fontFamily: '"JohnsonText-Medium"' }}
      >
        <InputLabel
          variant='standard'
          id='select-status-label'
          sx={{
            fontSize: screen.isTablet ? '20px': '16px',
            lineHeight: '14px',
            fontWeight: 500,
            fontFamily: '"JohnsonText-Bold"',
            color: 'rgba(33, 33, 33, 1)',
            width: '728px',
            height: '16px',
            marginTop : screen.isTablet ? '4px' : NUMBER_ARRAY.zero,
            paddingTop : screen.isTablet ? '2px' : NUMBER_ARRAY.zero
            }}
            shrink={true}
          >
            Status
          </InputLabel>
          <Select
            id='dd-select-status'
            data-testid='statusInput'
            IconComponent={DropDownIcon}
            value={selectedStatusView}
            onChange={handleSelectStatusChange}
            sx={{ borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}` }}
            displayEmpty={true}
            renderValue={selected => {
              if (selected.length === 0) {
                return (
                  <Typography
                    data-testid='statusInputSelect'
                    sx={{
                      color: redtheme.palette.fontColor.gray_orange,
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    Select
                  </Typography>
                );
              }
              return selectedStatusView;
            }}
          >
            <MenuItem value='All'>All</MenuItem>
            {TapGlobalMessages.myTeamsPerformance.statusOptionsList.map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>)
     
  }

  const customNoDataOverlay = () => {
    let overlayMssg =
      (selectedWWID === null || selectedWWID === '') 
        ? 'No Team Members assigned to you at the moment'
        : 'No Team Members found for the filters applied';

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          background: redtheme.palette.box.very_light_grey,
          fontWeight: '900',
          fontSize: '26px',
          lineHeight: '32px',
          fontFamily: 'J&J CircularTT Bold Web',
          color: redtheme.palette.box.dark_grayish_blue,
          width: '100%',
          height: '100%',
        }}
      >
        {overlayMssg}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginBottom: '30px',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ width: '100%' }}>

          {/* <TabPanel value={selectedTabValue} index={0}> */}
          <Box
            sx={{
              display: 'flex',
              marginBottom: '20px',
              flexDirection: 'row',
              width: screen.isMobile || screen.isTablet ? '100%' : '80%',
              flexWrap : screen.isMobile || screen.isTablet ?  'wrap' : {},
              alignItems : screen.isMobile || screen.isTablet ? 'center' : {}
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: screen.resolveStyles({ mobile: '40%', tablet: `${100 / 3}%`, desktop: '65%' }),
              }}
            >
              <Autocomplete
                data-testid='managerSingleteamWWIDFilter'
                popupIcon={DropDownIcon}
                options={selectedWWIDOptions}
                //freeSolo
                getOptionLabel={option => option.label || ''}
                value={selectedWWIDValue}
                onChange={(event, value, reason, details) => {
                  onChangeAutoCompleteFilter(event, value, reason, details, 'wwid');
                }}
                onInputChange={(event, value, reason) =>
                  handleAutoCompleteChange(event, value, reason, 'wwid')
                }
                //filterSelectedOptions
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.id}>
                    {option.label}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        shrink: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                        label={
                          <Typography
                              sx={{
                                fontSize: screen.isTablet ? '20px' :'16px',
                                lineHeight: '14px',
                                fontWeight: 500,
                                fontFamily: '"JohnsonText-Bold"',
                                color: 'rgba(33, 33, 33, 1)',
                                width: '728px',
                                height: '16px',
                                marginTop : screen.isTablet ? '3px' : '0px',
                              }}
                            >WWID / User Name</Typography>}
                      sx={{
                          borderBottom: '1px solid #81766F',
                          marginBottom: screen.isTablet && NUMBER_ARRAY.zero,
                      }}
                      variant='standard'
                      margin='normal'
                      placeholder='Search'
                  />
                )}
                sx={{ mr: 2, width: '100%' }}
                clearOnBlur
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '3vw',
                alignItems: 'end',
                marginLeft:  screen.isMobile ? '-18px' : NUMBER_ARRAY.zero
              }}
            >
              <ToolTipWrapper
                title={TapGlobalMessages.myTeamsPerformance.singleTeam.searchByMangOrWWIDInfoTooltip()}
                placement='top'
                header='How Search Works?'
              >
                <HelpOutlineIcon
                  fontSize='medium'
                  sx={{ color: `${theme.palette.common.primaryMainBlue}` }}
                ></HelpOutlineIcon>
              </ToolTipWrapper>
            </Box>
            {/* <Box sx={{
            {/* <Box sx={{
              fontSize: '16px',
              lineHeight: '14px',
              fontWeight: 500,
              fontFamily: '"JohnsonText-Bold"',
              color: 'rgba(33, 33, 33, 1)',
              width: '728px',
              height: '16px',
              }}>
              <Box sx={{ display: 'flex' }}> */}

            {filterdropdown()}

            <Box
              sx={{
                display: 'flex',
                width: '2vw',
                alignItems: 'end',
                marginLeft: screen.isMobile ? '-15px': NUMBER_ARRAY.zero
                //ml: 2,
              }}
            >
              <ToolTipWrapper
                title={TapGlobalMessages.myPerformance.myTrainingStatusDateRange()}
                placement='top'
                header=''
              >
                <HelpOutlineIcon
                  fontSize='medium'
                  sx={{ color: `${theme.palette.common.primaryMainBlue}` }}
                ></HelpOutlineIcon>
              </ToolTipWrapper>
            </Box>
            {/* </Box>
            </Box> */}
          </Box>
          {managerTable()}
          {/* <Box
            sx={{
              padding: '10px 0',
            }}
          > */}
          {/** Start DataGrid */}

          {/** End Grid Are */}
          {/* </Box> */}
          {/* </TabPanel> */}
        </Box>
      </Box>
      {childPopUpColumns &&
      childPopUpColumns?.length &&
      childPopUpColumns[0]?.columnArray &&
      childPopUpColumns[0]?.columnArray?.length ? (
        
        <GenericPopup
          open={openPopup}
          onClose={() => {
            setOpenPopup(false);
            setPopupHeader(null);
            
          }}
          header={popupHeader}
          columns={childPopUpColumns[0]?.columnArray}
          buttons={popupButtons}
          popupHeader={popupHeader}
          statusMapper={statusMapper}
          popUserId={popUserId}
          popupStatus={popupStatus}
          autocompletes={autocompleteConfigs}
          selectedStatus={pageState.selectedStatus}
          setAutocompleteConfigs={setAutocompleteConfigs}
          isCheckboxSelection={isCheckboxSelection}
        />
      ) : (
        ''
      )}
    </>
  );
};