import { RedTheme } from 'common/global';
import axios from 'axios';
import { EST_ONE_AM, NUMBER_ARRAY } from 'common/global/constants';

const TRAINING_CRITERIA_LOW = 85;
const TRAINING_CRITERIA_HIGH = 95;

/**
 *
 * @param {string} aTime
 * @returns {string}
 * Ex:
 * Input: 2024-01-03T20:14:13.986Z
 * Output: 04 Jan 2024, 01:44:13 AM UTC
 */

export const currentDate = new Date().toISOString('en-US', { timeZone: 'UTC' }).split('T')[0];
export const currentTime = new Date()
  .toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  })
  .replace(/[^ apm\d]+/gi, '_');

export const formatTime = (refreshTimeStamp) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let formattedTimeStamp = '';

  let d = new Date(refreshTimeStamp);
  let lastRefreshedDate = d.toLocaleString('en-US', { day: '2-digit', timeZone: 'UTC' });
  let lastRefreshedMonth = monthNames[d.getMonth()];
  let lastRefreshYear = d.getFullYear();
  let lastRefreshTime = d.toLocaleTimeString('en-US', { timeZone: 'UTC' }).split(':');

  /**
   *Add zero if hour, minute or second is in single digit
   * Ex:
   * Input: 1:44:13
   * Output: 01:44:13
   */

  let formattedLastRefreshTime = lastRefreshTime
    .map((component) => (component.length === 1 ? `0${component}` : component))
    .join(':');

  formattedTimeStamp = `${lastRefreshedDate} ${lastRefreshedMonth} ${lastRefreshYear}, ${formattedLastRefreshTime} EST`;

  return formattedTimeStamp;
};

export const getTextColorForComplianceTable = (flag) => {
  let redtheme = RedTheme;
  if (flag === 'G') {
    return redtheme.palette.dataGrid.light_moderate_lime_green;
  } else if (flag === 'Y') {
    return redtheme.palette.dataGrid.yellow;
  } else if (flag === 'R') {
    return redtheme.palette.dataGrid.red;
  } else {
    return redtheme.dataGrid.very_dark_grey;
  }
};

export const getTextColor = (TrainingValue) => {
  let redtheme = RedTheme;
  const percentage = parseInt(TrainingValue, 10);
  if (percentage < TRAINING_CRITERIA_LOW) {
    return redtheme.palette.dataGrid.red;
  } else if (percentage >= TRAINING_CRITERIA_LOW && percentage < TRAINING_CRITERIA_HIGH) {
    return redtheme.palette.dataGrid.yellow;
  } else {
    return redtheme.palette.dataGrid.light_moderate_lime_green;
  }
};

export const getDownloadedExcelReportData = (responseBlob) => {
  try {
    const responseBlobData = JSON.parse(responseBlob);
    const responseBlobType = typeof responseBlobData;
    if (responseBlobType === 'string' && responseBlobData === 'No') {
      return false;
    } else if (responseBlobType === 'object') {
      return responseBlobData?.data && responseBlobData?.data?.length === NUMBER_ARRAY.zero;
    } else return false;
  } catch (e) {
    return false;
  }
};
export const downloadExcelReport = async (requestURL, fileName, params) => {
  let reponseType = '';
  const response = await axios.get(requestURL, {
    responseType: 'blob',
    params: params,
  });
  try {
    const responseBlob = new Blob([response?.data], { type: 'text/plain;charset=utf-8' });
    reponseType = responseBlob.text().then((responseBlob) => {
      if (getDownloadedExcelReportData(responseBlob)) {
        return 'NO_DATA_FOUND';
      } else {
        /**
         * Checking the data response length. So,if the response from api is "No" then it's length is 4
         * (Length include double quotes as well) and other wise it will go for else condition to download the Excel!
         */
        if (response?.data?.size === NUMBER_ARRAY.four) return 'LIMIT_EXCEED';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return '';
      }
    });
    return reponseType;
  } catch (error) {
    return error;
  }
};

export const getComplianceStatusTextColor = (complianceStatus) => {
  let redtheme = RedTheme;

  if (complianceStatus === 'Non Compliant') {
    return redtheme.palette.dataGrid.red;
  } else if (complianceStatus === 'At Risk') {
    return redtheme.palette.dataGrid.yellow;
  } else {
    return redtheme.palette.text.secondary;
  }
};

export const getRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
};

export const logError = (msg, err) => {
  console.log(msg, err); //NOSONAR
};

/**
 * getDimensionWithUnit
 * It will return actual unit
 * Ex:
 * dimension: 2
 * unit: px
 * Output: 2px
 */
export const getDimensionWithUnit = (dimension, unit) => {
  return dimension + unit;
};

/**
 * Method is repsonsible to get the measurement Id based on the environment.
 * @returns string - Measurement Id for Google Analytics
 */
export const getGAMeasurementId = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  switch (environment) {
    case 'dev':
      return 'LH5XVZZBZS';
    case 'qa':
      return 'WNNXZJN80E';
    case 'production':
      return 'YRE0KSHXMN';
    default:
      return 'LH5XVZZBZS';
  }
};

/**
 * This method will confirm if it is 1 AM EST or not.
 * As per the Business ask, the site should show 'Under Maintenance' kind of message rather than showing 'User Not found'.
 * User not found was visible as the CDC pipeline is first truncating the users table and then dumping data into it. At the time of
 * truncation, the users table was empty hence 'Unauthorized Access' was visible to the end users.
 * @returns boolean
 */
export const isTimeOfRunningCDCPipeline = () => {
  // Get current local time
  const localTime = new Date();
  const newYorkTime = localTime.toLocaleString('en-US', { timeZone: 'America/New_York' });
  const newYorkHour = parseInt(newYorkTime.split(' ')[1].split(':')[0]);
  return newYorkHour === EST_ONE_AM;
};
