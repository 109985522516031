import React, { useState, useRef, useEffect, useMemo, Fragment } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { getDecodedBearerToken } from 'common/authentication/authToken';
import { NUMBER_ARRAY } from 'common/global/constants';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { TapGlobalMessages } from 'TAP/global';

const NavigationMenuMobile = () => {
  // const { routeLabels, routePaths } = TapGlobalMessages;
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [userRole, setUserRole] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);
  const menuItemts = useMemo(
    () => [
      { label: 'My Performance', path: '/' },
      ...([NUMBER_ARRAY.two, NUMBER_ARRAY.three].includes(userRole)
        ? [{ label: `My Team's Performance`, path: '/TAP/myorganisation' }]
        : []),
      { label: 'Training Plan View', path: '/TAP/trainingview' },
      ...(isAdmin ? [{ label: 'Administrative View', path: '/TAP/adminview' }] : []),
    ],
    [userRole, isAdmin],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const userDetails = localStorage.getItem('userDetails');
      const parseUserDetails = userDetails ? JSON.parse(userDetails) : '';
      const decodedBearerToken = getDecodedBearerToken();

      setIsAdmin(decodedBearerToken && decodedBearerToken?.roles && decodedBearerToken.roles?.length > 0);

      if (parseUserDetails && Object.keys(parseUserDetails).length) {
        setUserRole(parseInt(parseUserDetails?.user_role));
      } else {
        setUserRole(1);
      }
    }, NUMBER_ARRAY.eight_hundred);
    return () => clearTimeout(timer);
  }, [userRole]);

  const handleMenuClick = path =>{ 
    navigate(path);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        ref={menuRef}
        onClick={() => setAnchorEl(menuRef.current)}
        data-testid = 'navigationMenuRef'
      >
        <MenuIcon sx={{ color: 'black' }} />
      </Box>
      <Menu
        data-testid = 'navigationMenuMobile'
        disableScrollLock
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          borderRadius: '12px',
        }}
      >
        {menuItemts.map((item, index, self) => (
          <Box width='100%' key={item.label}>
            <MenuItem
              sx={{
                fontSize: '14px',
                lineHeight: '16px',
                fontFamily: "'JohnsonText-Regular, sans-serif'",
              }}
              onClick={() => handleMenuClick(item.path)}
            >
              {item.label}
            </MenuItem>
            {index !== self.length - NUMBER_ARRAY.one && <Divider />}
          </Box>
        ))}
      </Menu>
    </Fragment>
  );
};

export default NavigationMenuMobile;
