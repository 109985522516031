import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GetTrainingsPopupData, GetTrainingsSuggestionsInPopup } from 'TAP/redux/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@mui/material/InputAdornment';

import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as Download } from 'common/assets/images/Down_load.svg';
import { GET_TRAININGVIEW_POPUP_REPORT } from 'TAP/redux/actions/apiPathConstants';
import { RedTheme } from 'common/global';
import { ToolTip } from 'common/components/toolTip';
import { PAGE_SIZES_ARRAY_GENERIC } from 'common/global/constants';
import { downloadExcelReport, currentDate, currentTime } from 'TAP/utils/commonMethods';
import { useScreen } from 'common/hooks/useScreen';
import { tablePaginationClasses } from '@mui/material/TablePagination';

export const TrainingViewPopup = ({
  userID,
  curriculumID,
  curriculumTitle,
  open,
  onPopupClose,
}) => {
  const dispatch = useDispatch();
  let redtheme = RedTheme;
  const [trainingsData, setTrainingsData] = useState([]);
  const [sortField, setSortField] = useState('');
  const [sortOrderBy, setSortOrderBy] = useState('');
  const [searchTermTraining, setSearchTermTraining] = useState('');
  const [searchTrainingsOptions, setSearchTrainingsOptions] = useState([]);
  const [searchSelectedTraining, setSearchSelectedTraining] = useState('');
  const [noTrainingsFound, setNoTrainingsFound] = useState(false);
  const screen = useScreen();
  const [pageState, setPageState] = useState({
    total: 0,
    page: 0,
    pageSize: 5,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const [sortingTooltip, setSortingTooltip] = useState('');

  const CLICK_TO_SORT_ASCENDING = 'Click to sort ascending';
  const CLICK_TO_SORT_DESCENDING = 'Click to sort descending';

  useEffect(() => {
    setSortingTooltip(CLICK_TO_SORT_ASCENDING);
  }, []);

  const handleClose = () => {
    setSearchSelectedTraining('');
    setSortField('');
    setSortOrderBy('');
    setPageState({
      total: 0,
      page: 0,
      pageSize: 5,
    });
    setPaginationModel({
      page: 0,
      pageSize: 5,
    });
    let payload = {
      userId: userID,
      curriculumId: '0',
      apiType: 'LOAD',
    };
    dispatch(GetTrainingsPopupData(payload));

    onPopupClose && onPopupClose();
  };

  const columns = [
    {
      field: 'training_title',
      headerName: 'Training Name',
      flex: screen.isMobile ? 0 : 4,
      width: screen.isMobile ? 200 : 300,
      disableColumnMenu: true,
      renderCell: (params) => {
        const training_title = params.value || '';
        return (
          <ToolTip title={training_title} placement='top' header='Training Name' noOfLetters={52} />
        );
      },
    },
    {
      field: 'training_code',
      headerName: 'Training Code',
      flex: screen.isMobile ? 0 : 1.6,
      width: screen.isMobile ? 200 : 300,
      disableColumnMenu: true,
      renderCell: (params) => {
        const training_code = params.value || '';
        return (
          <ToolTip title={training_code} placement='top' header='Training Code' noOfLetters={16} />
        );
      },
    },
  ];

  useEffect(() => {
    if (open && userID?.length && curriculumID?.length) {
      let payload = {};

      if (searchSelectedTraining?.length) {
        payload = {
          userId: userID,
          curriculumId: curriculumID,
          searchValue: searchSelectedTraining,
          apiType: 'SEARCH',
        };
      } else {
        payload = {
          userId: userID,
          curriculumId: curriculumID,
          page: pageState.page,
          limit: pageState.pageSize,
          sortField: sortField,
          orderBy: sortOrderBy,
          apiType: 'LOAD',
        };
      }
      dispatch(GetTrainingsPopupData(payload));
    }
  }, [
    open,
    userID,
    curriculumID,
    pageState.page,
    pageState.pageSize,
    sortField,
    sortOrderBy,
    searchSelectedTraining,
  ]);

  let trainingsPopupData = useSelector(
    (state) => state?.tapTrainingPlanPopupRd?.trainingsPopupData?.data,
  );

  let isLoadingData = useSelector(
    (state) => state?.tapTrainingPlanPopupRd?.isLoadingTrainingsPopupData,
  );

  useEffect(() => {
    if (trainingsPopupData !== undefined) {
      if (trainingsPopupData.records !== undefined) {
        setTrainingsData(trainingsPopupData.records);
      }
      if (trainingsPopupData.totalRecords !== undefined) {
        trainingsPopupData.totalRecords === 0
          ? setNoTrainingsFound(true)
          : setNoTrainingsFound(false);

        setPageState((old) => ({
          ...old,
          total: trainingsPopupData.totalRecords,
        }));
      }
    }
  }, [trainingsPopupData]);

  const onInputChangeOfAutoCompleteFilter = (_event, value, reason) => {
    setSearchTrainingsOptions([]);

    if (value !== undefined && value !== '') {
      setSearchTermTraining(value);
    } else {
      setSearchTermTraining('');
      setSearchTrainingsOptions([]);
    }
    if (reason === 'clear' || reason === 'reset') {
      setSearchTermTraining('');
      setSearchTrainingsOptions([]);
    }
  };

  useEffect(() => {
    if (userID?.length && curriculumID?.length && searchTermTraining?.length) {
      const payload = {
        userId: userID,
        curriculumId: curriculumID,
        searchValue: searchTermTraining,
        apiType: 'SUGGESTION',
      };
      dispatch(GetTrainingsSuggestionsInPopup(payload));
    }
  }, [userID, searchTermTraining]);

  let trainingsSuggestionsForSearch = useSelector(
    (state) => state?.tapTrainingPlanPopupRd?.trainingsSuggestionsForSearch?.data?.records,
  );

  useEffect(() => {
    if (trainingsSuggestionsForSearch !== undefined) {
      setSearchTrainingsOptions(trainingsSuggestionsForSearch);
    }
  }, [trainingsSuggestionsForSearch]);

  const onChangeOfAutoCompleteFilter = (_event, newValue) => {
    if (newValue && newValue !== null) {
      if (newValue?.training_code && newValue?.training_code !== null)
        setSearchSelectedTraining(newValue?.training_code);
      else setSearchSelectedTraining('');
    } else setSearchSelectedTraining('');

    setPageState((old) => ({
      ...old,
      page: 0,
    }));
    setPaginationModel((old) => ({
      ...old,
      page: 0,
    }));
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel !== undefined) {
      setSortField(sortModel[0]?.field);
      setSortOrderBy(sortModel[0]?.sort);
    }
    if (sortModel[0]?.sort.toUpperCase() === 'ASC') {
      setSortingTooltip(CLICK_TO_SORT_DESCENDING);
    } else if (sortModel[0]?.sort.toUpperCase() === 'DESC') {
      setSortingTooltip(CLICK_TO_SORT_ASCENDING);
    }
  };

  const handelDownloadExcel = () => {
    let fileName = `TrainingViewReport_${userID}_${currentDate}_${currentTime}.xlsx`;

    if (userID !== undefined && curriculumID !== undefined && curriculumID !== '') {
      let requestURL = `${
        process.env.REACT_APP_API_BASE_URL
      }${GET_TRAININGVIEW_POPUP_REPORT()}?userId=${userID}&curriculumId=${encodeURIComponent(
        curriculumID,
      )}&apiType=POP_UP`;
      if (searchSelectedTraining?.length) {
        requestURL = requestURL + `&trainingCode=${encodeURIComponent(searchSelectedTraining)}`;
      }
      downloadExcelReport(requestURL, fileName, null);
    }
  };

  const customNoDataOverlay = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          background: redtheme.palette.box.very_light_grey,
          fontWeight: 400,
          fontSize: '32px',
          lineHeight: '40px',
          fontFamily: '"JohnsonDisplay-Regular"',
          color: redtheme.palette.box.dark_grayish_blue,
          width: '100%',
          height: '100%',
        }}
      >
        No Trainings found for you at the moment
      </Box>
    );
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: redtheme.palette.box.white,
            padding: '32px 46px 40px',
            gap: '48px',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: screen.isMobile ? '320px' : '720px',
            height: screen.isMobile ? '510px' : '601px',
            borderRadius: '16px',
          }}
        >
          <IconButton
            className='btn-close-popup'
            data-testid='btn-close'
            sx={{
              position: 'absolute',
              top: screen.isMobile ? '10px' : '30px',
              right: screen.isMobile ? '10px' : '30px',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ height: screen.isMobile ? '25%' : '15%' }}>
            <Typography
              variant='h2'
              component='div'
              sx={{
                weight: 700,
                fontFamily: '"JohnsonDisplay-Bold"',
                width: '95%' /*, width: '750px'*/,
                lineHeight: screen.isMobile ? '20px' : '24px',
                fontSize: screen.isMobile ? '18px' : '23px',
              }}
            >
              {curriculumTitle}
            </Typography>
          </Box>

          {userID && curriculumID && !noTrainingsFound ? (
            <Box display='flex' flexDirection='row' mb={2}>
              <Autocomplete
                data-testid='autocomplete-search'
                options={searchTrainingsOptions}
                getOptionLabel={(option) => option.search_value}
                onInputChange={onInputChangeOfAutoCompleteFilter}
                onChange={onChangeOfAutoCompleteFilter}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      shrink: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label='Training Name'
                    sx={{
                      borderBottom: '1px solid #81766F',
                    }}
                    variant='standard'
                    margin='normal'
                    placeholder='Search'
                  />
                )}
                sx={{ mr: 2, width: '100%' }}
                clearOnBlur
              />
            </Box>
          ) : (
            <></>
          )}

          <Box sx={{ height: screen.isMobile ? '45%' : '60%', width: '100%' }}>
            {noTrainingsFound ? (
              <DataGrid
                loading={isLoadingData}
                rows={[]}
                columns={columns}
                components={{
                  noRowsOverlay: customNoDataOverlay,
                }}
                sx={{
                  border: 'none',
                  minHeight: '400px',
                  '& .MuiDataGrid-columnHeaders': {
                    fontSize: 16,
                    fontFamily: '"JohnsonText-Bold"',
                  },
                }}
              />
            ) : (
              <DataGrid
                rows={trainingsData}
                columns={columns}
                getRowId={(row) => row?.training_id}
                loading={isLoadingData}
                disableRowSelectionOnClick
                sortingMode='server'
                paginationMode='server'
                localeText={{ columnHeaderSortIconLabel: sortingTooltip }}
                onSortModelChange={handleSortModelChange}
                rowCount={pageState.total}
                paginationModel={paginationModel}
                pageSizeOptions={PAGE_SIZES_ARRAY_GENERIC}
                onPaginationModelChange={(newPage) => {
                  setPageState((old) => ({
                    ...old,
                    page: newPage.page + 1,
                    pageSize: newPage.pageSize,
                  }));
                  setPaginationModel((old) => ({
                    ...old,
                    page: newPage.page,
                    pageSize: newPage.pageSize,
                  }));
                }}
                onPageSizeChange={(newPageSize) =>
                  setPageState((old) => ({
                    ...old,
                    pageSize: newPageSize,
                  }))
                }
                sx={{
                  border: 'none',
                  '& .MuiDataGrid-cell': {
                    fontSize: '16px',
                    fontWeight: 500,
                    fontFamily: '"JohnsonText-Light"',
                    lineHeight: '16px',
                  },
                  '& .MuiDataGrid-row:nth-of-type(odd)': { backgroundColor: '#00009908' },
                  '& .MuiDataGrid-columnHeaders': {
                    fontSize: '16px',
                    fontFamily: '"JohnsonText-Bold"',
                    fontWeight: 700,
                    lineHeight: '18px',
                  },
                  '& .MuiTablePagination-selectLabel': {
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '15px',
                    fontStyle: 'normal',
                    color: redtheme.palette.dataGrid.very_dark_grey,
                  },
                  '& .MuiTablePagination-displayedRows': {
                    fontFamily: '"JohnsonText-Medium"',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '15px',
                    fontStyle: 'normal',
                    color: redtheme.palette.dataGrid.very_dark_grey,
                  },
                  '& .MuiTablePagination-select': {
                    fontFamily: '"JohnsonText-Medium"',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '21px',
                    fontStyle: 'normal',
                    color: redtheme.palette.dataGrid.very_dark_grey,
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: 'none !important',
                  },
                  [`& .${tablePaginationClasses.selectLabel}`]: {
                    display: 'block',
                  },
                  [`& .${tablePaginationClasses.input}`]: {
                    display: 'inline-flex',
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: screen.isMobile ? 'center' : 'flex-end',
            }}
          >
            <Stack direction='row' spacing={2} mt={2}>
              {userID && curriculumID && !noTrainingsFound ? (
                <Button
                  data-testid='download-excel'
                  onClick={handelDownloadExcel}
                  sx={{
                    bgcolor: redtheme.palette.button.pure_red,
                    color: redtheme.palette.button.white,
                    padding: '8px, 16px, 8px, 16px',
                    borderRadius: '10px',
                    gap: '10px',
                    textTransform: 'none',
                    fontWeight: 500,
                    fontSize: '16px',
                    width: '166px',
                    height: '34px',
                    fontFamily: '"JohnsonText-Light"',
                    '&:hover': {
                      backgroundColor: redtheme.palette.button.pure_red,
                      color: redtheme.palette.button.white,
                    },
                    '&:focus': { outline: 'none' },
                    lineHeight: '29px',
                    marginLeft: '565px',
                    marginRight: '51px',
                  }}
                  disabled={userID === undefined || curriculumID === undefined || noTrainingsFound}
                >
                  Download Excel <Download />
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
