export const AdminViewCompleteStatReducer = (state = {}, action) => {
  if (action.type === 'POST_ADMINVIEW_COMPLETED_STATUS_DETAILS') {
    return {
      ...state,
      adminViewCompleteStatusAPIResponse: action.payload,
    };
  } else {
    if (action.type === 'API_START') {
      return {
        ...state,
        isLoadingAdminViewCSReducer: true,
      };
    } else if (action.type === 'API_END') {
      return {
        ...state,
        isLoadingAdminViewCSReducer: false,
      };
    } else {
      return state;
    }
  }
};
