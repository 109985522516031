import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { RedTheme } from 'common/global';
import { CircleWithText, ToolTipWrapper, DropDownIcon } from 'common/components';
import { useScreen } from 'common/hooks/useScreen';

export const TrainingTile = ({
  handleSelectedDaysChange,
  handlePopupButtonClick,
  progressData,
  toolTipHeaderText,
  toolTipTitleText,
  selectedDays,
  actionType = 'TRAININGS',
  tileTypeText = 'COMING DUE',
  fontcolor, 
  isComplianceAction
}) => {

  const redtheme = RedTheme;

// Decides Header of the Popup on click of See More
  let popupHeaderText = '';
  if (tileTypeText === 'COMING DUE'){
    (popupHeaderText = 'Coming Due Trainings')
  } else if (tileTypeText === 'AT RISK'){
    (popupHeaderText = 'Total At Risk')
  } else if (tileTypeText === 'NON-COMPLIANT'){
    (popupHeaderText = 'Total Non-Compliant')
  } else {
    (popupHeaderText = 'Overdue Trainings');
  }

  const screen = useScreen(); 

  let CircleWithTextbgcolor;
  if(tileTypeText === 'COMING DUE'  || tileTypeText === 'AT RISK'){
    CircleWithTextbgcolor = redtheme.palette.span.yellow ;
  } else {
    CircleWithTextbgcolor = redtheme.palette.dataGrid.strong_red ;
  }

  return (
    <Box
      sx={{
        height: '110px',
        // width: '49%',
        borderLeft: `3px solid ${redtheme.palette.info.borderBlue}`,
        borderRadius: '8px',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        justifyContent: screen.isMobile?'left':'center',
        paddingLeft: "28px"
      }}
    >
      <Stack direction='row' spacing={1.5} sx={{ alignItems: 'center' }}>
        <CircleWithText
          id='cwt3'
          progressData={progressData}
          data={
            <ToolTipWrapper header={toolTipHeaderText} title={toolTipTitleText} placement='bottom'>
              {
                <Typography
                  variant='span'
                  component='span'
                  sx={{
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                    cursor: 'pointer',
                    //TODO Add Conditional color
                    color: fontcolor,
                  }}
                  data-testid='trainingsComingDueText'
                >
                  {progressData || 0}
                </Typography>
              }
            </ToolTipWrapper>
          }
          size={screen.isTablet ? '52' : '68'}
          fontWeight={screen.isTablet ? '700':'900'}
          fontSize={screen.isTablet ? '14':'16'}
          lineHeight={screen.isTablet ? '14':'16'}
          marginBottom='0'
          fontFamily='"JohnsonText-Medium"'
          bgColor={CircleWithTextbgcolor}
          fgColor={redtheme.palette.dataGrid.fontGrey}
        />
        <Stack
          direction='column'
          sx={{
            fontSize: '14px',
            lineHeight: '14px',
            width:  tileTypeText === 'COMING DUE' ? screen.isTablet ? '120px' : '190px' : '116px',
            fontWeight: 500,
            justifyContent: 'center',
            fontFamily: '"JohnsonText-Medium"',
          }}
        >
          <Box mt='25px'>{screen.isTablet && tileTypeText === 'NON-COMPLIANT' ? actionType + ' NON' :  actionType}</Box>
          <Box>{screen.isTablet && tileTypeText === 'NON-COMPLIANT' ? 'COMPLIANT':tileTypeText}</Box>
          { tileTypeText === 'COMING DUE'  ? (
            <Box sx={{ color: redtheme.palette.span.yellow, mt: '-10px' }}>
              <span
                style={{
                  color: redtheme.palette.fontColor.black,
                  display: 'inline-block',
                  marginRight: screen.isTablet ? '0px' : '5px',
                  marginTop: screen.isTablet ? '10px' : '0px',
                }}
              >
                IN THE NEXT{' '}
              </span>
              <Box component='span' sx={{ display: screen.isTablet ?'block' : 'inline-block', padding: 0 }}>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'></InputLabel>
                <Select
                  defaultValue={7}
                  IconComponent={DropDownIcon}
                  disableUnderline
                  value={selectedDays}
                  onChange={handleSelectedDaysChange}
                  variant='standard'
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: '12px',
                      },
                    },
                  }}
                  sx={{
                    textDecoration: 'none',

                    '& .MuiSelect-select': {
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      fontFamily: '"JohnsonText-Medium"',
                      color: redtheme.palette.span.yellow,
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                      fontWeight: 900,
                    },
                  }}
                  data-testid='select-time'
                >
                  <MenuItem
                    value={3}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    3 DAYS
                  </MenuItem>
                  <MenuItem
                    value={7}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    7 DAYS
                  </MenuItem>
                  <MenuItem
                    value={15}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    15 DAYS
                  </MenuItem>
                  <MenuItem
                    value={30}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    1 MONTH
                  </MenuItem>
                  <MenuItem
                    value={90}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    3 MONTHS
                  </MenuItem>
                
                </Select>
              </Box>
            </Box>
          ) : (
            ''
          )}
          <Box component='span'>
            <Button
              sx={{
                color:
                   tileTypeText === 'COMING DUE' || tileTypeText === 'AT RISK'
                    ? redtheme.palette.span.yellow
                    : redtheme.palette.dataGrid.strong_red,
                fontSize: '10px',
                fontWeight: 900,
                ml: '-8px',
                mb: '10px',
                mt:  tileTypeText === 'COMING DUE'  ? '-18px' : '0px',
                fontFamily: '"JohnsonText-Bold"',
                bgcolor: 'transparent',
                '&:hover': { backgroundColor: 'transparent', boxShadow: '' },
                '&:focus': { outline: 'none', backgroundColor: 'transparent' },
              }}
              disabled={progressData <= 0}
              onClick={() => handlePopupButtonClick(popupHeaderText, isComplianceAction)}
              data-testid='seeMoreButton'
            >
              See More
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
