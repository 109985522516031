import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Divider, FormControl, InputLabel, MenuItem, Select, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { LargeReportDownloadAction } from 'TAP/redux/actions';
import {
  formatTime,
  getComplianceStatusTextColor,
  getDimensionWithUnit,
  getGAMeasurementId,
} from 'TAP/utils/commonMethods';
import { ToolTipWrapper, ToolTip, DropDownIcon } from 'common/components';
import { SelectedTheme, BlueTheme, RedTheme } from 'common/global';
import GenericPopup from 'common/popup';
import { TapGlobalMessages } from 'TAP/global';
import { ShowLoader } from 'TAP/global/showLoader';
import { ActionButtonWrapper } from 'TAP/components/ActionButtonWrapper';
import { GraphTile } from 'TAP/components/graphTile';
import { LeaveIndicator } from 'TAP/components/leaveIndicator';
import { NonCompliantTooltipBody, AtRiskTooltipBody } from 'TAP/components';
import { ManagerSingleTeamTable } from 'TAP/pages/managerSingleTeamView';
import { ManagerManyTeamsTable } from 'TAP/pages/managerManyTeamsView';
import {
  TrainingOverdueData,
  TrainingUpcomingOverdueData,
  DirectTrainingOverdueData,
  DirectTrainingUpcomingOverdueData,
  MyOrgCompletionData,
  MyDirectCompletionData,
  MyOrgComplianceData,
  MyDirectReporteeComplianceData,
  SearchMyOrgTrainingsData,
  NonCompliantData,
  AtRiskData,
  DirectNonCompliantData,
  DirectAtRiskData,
  ReportDownloadAction,
} from 'TAP/redux/actions';
import { myOrgPerform } from 'common/global/constants';
import { userRole } from 'TAP/styles/genericStyles/userRole';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights';
import { IOSSwitch } from 'common/components/switch';
import { padding } from 'TAP/styles/genericStyles/padding';
import { gap } from 'TAP/styles/genericStyles/gap';
import { width } from 'TAP/styles/genericStyles/width';
import { height } from 'TAP/styles/genericStyles/height';
import { fontsize } from 'TAP/styles/genericStyles/fontSize';
import { lineHeight } from 'TAP/styles/genericStyles/lineHeight';
import { textTransform } from 'TAP/styles/genericStyles/textTransform';
import { fontFamily } from 'TAP/styles/genericStyles/fontFamily';
import { spacing } from 'TAP/styles/genericStyles/spacing';
import ReactGA from 'react-ga4';
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';
import { Toaster } from 'TAP/components/Toaster';

const switchStateValues = {
  onTime: { label: 'On Time' },
  overall: { label: 'Overall' },
};

const statusMapper = {
  'Overdue Trainings': 'trainings/totalOverDue',
  'Coming Due Trainings': 'trainings/upcomingDue',
  'Total Non-Compliant': 'actions/nonCompliant',
  'Total At Risk': 'actions/atRisk',
};
const directReporteeStatusMapper = {
  'Overdue Trainings': 'directReportTrainings/totalOverDue',
  'Coming Due Trainings': 'directReportTrainings/upcomingDue',
  'Total Non-Compliant': 'directReport/actions/nonCompliant',
  'Total At Risk': 'directReport/actions/atRisk',
};

const largeDownloadSuccessMsg =
  'The report is ready and you should receive the email with the link of shared path where you can navigate & access the report.';
const largeDownloadStartMsg =
  'Record count is more than 100K. You will shortly receive an email with the link to access the report.';

const timeRangeValues = { year: 'Year', quarter: 'Quarter', month: 'Month' };
let redtheme = RedTheme;

export const MyOrgPerformance = () => {
  const [initializer, setInitializer] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [trainingOverdueData, setTrainingOverdueData] = useState('');
  const [trainingUpcomingDueData, setTrainingUpcomingDueData] = useState('');
  const [totalNonCompliantData, setTotalNonCompliantData] = useState('');
  const [totalAtRiskData, setTotalAtRiskData] = useState('');
  const [selectedDays, setSelectedDays] = useState(myOrgPerform.seven);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [trainingState, setTrainingState] = useState(switchStateValues.onTime);
  const [switchState, setSwitchState] = useState(switchStateValues.overall);
  const [activeFilter, setActiveFilter] = useState(myOrgPerform.two);
  const [timeRangeState, setTimeRangeState] = useState(timeRangeValues.quarter);
  const [myOrgCompletedData, setMyOrgCompletedData] = useState(0);
  const [logInUserDetails, setLogInUserDetails] = useState('');
  const [showLargeDataDownloadSuccessMsg, setShowLargeDataDownloadSuccessMsg] = useState(false);

  const [searchTermUser, setSearchTermUser] = useState('');
  const [searchUsersOptions, setSearchUsersOptions] = useState([]);
  const [searchSelectedUser, setSearchSelectedUser] = useState('');

  const [openPopup, setOpenPopup] = useState(false);
  const [popupHeader, setPopupHeader] = useState('');
  const [popupButtons, setPopupButtons] = useState([]);
  const [popUserId, setPopUserId] = useState('');
  const [popupStatus, setPopupStatus] = useState('');
  const [popUpName, setPopUpName] = useState('');
  const [autocompleteConfigs, setAutocompleteConfigs] = useState([]);
  const [complianceautocompleteConfigs, setcomplianceautocompleteConfigs] = useState([]);
  const [isCheckboxSelection, setIsCheckboxSelection] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedStatusView, setSelectedStatusView] = useState('');
  const [downloadReportType, setDownloadReportType] = useState('');
  const [largeDataDownloadMsg, setLargeDataDownloadMsg] = useState(largeDownloadStartMsg);
  const [isCompliantPopup, setIsCompliantPopup] = useState(false);
  const [activeButton, setActiveButton] = useState(myOrgPerform.two);
  const [toasterSeverity, setToasterSeverity] = useState('warning');
  const [reportDownloadPayload, setReportDownloadPayload] = useState({});
  const [AVCompleteStatus, setAVCompleteStatus] = useState(false);
  const screen = useScreen();

  useEffect(() => {
    ReactGA.initialize(`G-${getGAMeasurementId()}`);
    ReactGA.send({
      hitType: 'pageview',
      page: '/TAP/myorganisation',
      title: "My Team's Performance",
    });
  }, []);

  useEffect(() => {
    if (!initializer) {
      const userDetails = localStorage.getItem('userDetails');
      if (userDetails && Object.keys(userDetails).length) {
        setLogInUserDetails(JSON.parse(userDetails));
        setSelectedTabValue(JSON.parse(userDetails).user_role === userRole.usr3 ? 1 : 0);
      }

      setInitializer(true);
    }
  }, [initializer]);

  /**
   * handleButtonClick.
   * Once user click on tab it will update data accordingly
   * Use Stack Component
   * Use Typography Component
   * @param {string} button
   * @param {string} timeRange
   */
  const handleButtonClick = (button) => {
    if (activeButton !== button) {
      setActiveButton(button);
    }
  };

  const handleTabChange = (_event, newValue) => {
    setSearchSelectedUser('');
    setSelectedTabValue(newValue);
    setSelectedDateRange([]);
    setIsLoaded(false);
  };

  const lastRefreshed = () => {
    if (logInUserDetails && Object.keys(logInUserDetails).length) {
      return formatTime(logInUserDetails?.last_refresh_date);
    }
  };

  const dispatch = useDispatch();
  let dashboardData;
  let complianceActionsData;
  let myOrgTrainingCompletionData;
  let myOrgTrainingComplianceData;

  if (selectedTabValue === 1) {
    dashboardData = useSelector((state) => state.teamPerformanceRd);
    complianceActionsData = useSelector((state) => state.myOrgComplianceActionsRd);
    myOrgTrainingCompletionData = useSelector((state) => state.myOrgTrainingCompletionRd);
    myOrgTrainingComplianceData = useSelector((state) => state.myOrgComplianceProfileRd);
  } else {
    dashboardData = useSelector((state) => state.teamPerformanceDirectRd);
    complianceActionsData = useSelector((state) => state.directReporteeComplianceActionsRd);
    myOrgTrainingCompletionData = useSelector((state) => state.myDirectTrainingCompletionRd);
    myOrgTrainingComplianceData = useSelector((state) => state.MyDirectReporteeComplianceProfileRd);
  }

  const myTeamsPerformanceReportDownload = useSelector(
    (state) => state.teamsPerformanceDownloadReducer?.APIResponse,
  );

  const apiResponseOfLargeDataDownload = useSelector(
    (state) => state.teamsPerformanceLargeDownloadReducer?.APIResponse?.data,
  );

  const initiateLargeReportDownload = useSelector(
    (state) => state.teamsPerformanceDownloadReducer?.initiateLargeReportDownload,
  );

  const isFetchingAVCompleteStatus = useSelector(
    (state) => state.tapAdminViewCompleteStatusRd?.isLoadingAdminViewCSReducer,
  );

  useEffect(() => {
    setAVCompleteStatus(isFetchingAVCompleteStatus);
  }, [isFetchingAVCompleteStatus]);

  useEffect(() => {
    if (apiResponseOfLargeDataDownload === 'Request raised successfully' && initiateLargeReportDownload) {
      setLargeDataDownloadMsg(largeDownloadSuccessMsg);
      setToasterSeverity('success');
      setShowLargeDataDownloadSuccessMsg(true);
    }
  }, [apiResponseOfLargeDataDownload]);

  useEffect(() => {
    if (initiateLargeReportDownload) {
      dispatch(
        LargeReportDownloadAction({
          days: '',
          userId: logInUserDetails?.user_id,
          teamType: selectedTabValue === 1 ? 'myOrganisation' : 'myDirectReport',
          reportType: downloadReportType,
          levelValue: timeRangeState,
          trainingTitle: '',
          userName: logInUserDetails?.userName,
          startDate: '',
          endDate: '',
          dataOf: '',
          userArray: '',
          exclUserArray: '',
          workerType: '',
          patnerCompany: '',
          courseCode: '',
        }),
      );
      setShowLargeDataDownloadSuccessMsg(true);
      setLargeDataDownloadMsg(largeDownloadStartMsg);
      setToasterSeverity('warning');
    }
  }, [initiateLargeReportDownload]);

  useEffect(() => {
    if (myTeamsPerformanceReportDownload) {
      /**
       * We are not using common method to get the current date and time
       * as its not giving us proper timing (TODO: need to check the RC)
       * so for the time being using this component level code
       */
      const currDate = new Date().toISOString('en-US', { timeZone: 'UTC' }).split('T')[0];
      const currTime = new Date()
        .toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'UTC',
        })
        .replace(/[^ apm\d]+/gi, '_');
      const url = window.URL.createObjectURL(new Blob([myTeamsPerformanceReportDownload]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `MyTeamPerformanceOverallData_${currDate}_${currTime}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [myTeamsPerformanceReportDownload]);

  /**
   * Receives the team-type, reportType, levelValue, userId parameters and triggers the
   * Download event
   */
  const handleDownloadExcel = (reportType) => {
    dispatch(
      ReportDownloadAction({
        ...reportDownloadPayload,
        reportType: reportType,
        levelValue: timeRangeState,
      }),
    );
    setDownloadReportType(reportType);
  };

  const theme = SelectedTheme();
  let complementTheme = theme.palette.type === 'red' ? BlueTheme : RedTheme;

  const switchTheme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: RedTheme.palette.button.white,
          },
          colorPrimary: {
            '&.Mui-checked': {
              // Controls checked color for the thumb
              color: complementTheme.palette.primary.main,
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            backgroundColor: '#C1BBB3',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              backgroundColor: '#888B8D',
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    if (
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      const payloadUser = {
        //TODO get assigned role with training data
        userId: logInUserDetails?.user_id,
        loggedInUserId: logInUserDetails?.user_id,
      };
      const payloadDownload = {
        userId: logInUserDetails?.user_id,
      };
      if (selectedTabValue === 1) {
        // My Organisation Tab
        dispatch(TrainingOverdueData(payloadUser));
        dispatch(NonCompliantData(payloadUser));
        dispatch(AtRiskData(payloadUser));
        dispatch(MyOrgComplianceData(payloadUser));
        setReportDownloadPayload({
          ...payloadDownload,
          teamType: 'myOrganisation',
        });
      } else {
        // My Direct Reports Tab
        dispatch(DirectTrainingOverdueData(payloadUser));
        dispatch(DirectNonCompliantData(payloadUser));
        dispatch(DirectAtRiskData(payloadUser));
        dispatch(MyDirectReporteeComplianceData(payloadUser));
        setReportDownloadPayload({
          ...payloadDownload,
          teamType: 'mydirectreport',
        });
      }
    }
  }, [logInUserDetails, selectedTabValue]);

  useEffect(() => {
    setIsDisabled(true);

    if (
      trainingState.label !== undefined &&
      trainingState.label !== '' &&
      timeRangeState !== undefined &&
      timeRangeState !== '' &&
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      const payloadCompletedStatus = {
        //TODO get assigned role with training data
        userId: logInUserDetails?.user_id,
        status: 'Completed ' + trainingState.label,
        level: timeRangeState,
      };
      selectedTabValue === 1
        ? dispatch(MyOrgCompletionData(payloadCompletedStatus))
        : dispatch(MyDirectCompletionData(payloadCompletedStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logInUserDetails, trainingState.label, timeRangeState, selectedTabValue]);

  const handleSelectedDaysChange = (event) => {
    const days = event.target.value;
    setSelectedDays(days);
  };

  useEffect(() => {
    if (
      selectedDays !== undefined &&
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      const payloadUser = {
        userId: logInUserDetails?.user_id,
        days: selectedDays,
        loggedInUserId: logInUserDetails?.user_id,
      };
      selectedTabValue === 1
        ? dispatch(TrainingUpcomingOverdueData(payloadUser))
        : dispatch(DirectTrainingUpcomingOverdueData(payloadUser));
    }
  }, [logInUserDetails, selectedDays, selectedTabValue]);

  useEffect(() => {
    if (dashboardData !== undefined) {
      setTrainingOverdueData(dashboardData?.trainingOverdueData?.data);
      setTrainingUpcomingDueData(dashboardData?.trainingUpcomingdueData?.data);
    } else {
      setTrainingOverdueData('');
      setTrainingUpcomingDueData('');
    }
  }, [dashboardData]);

  useEffect(() => {
    if (complianceActionsData !== undefined) {
      setTotalNonCompliantData(
        complianceActionsData?.nonCompliantData?.data[0]?.totalNonCompliantCount,
      );
      setTotalAtRiskData(complianceActionsData?.atRiskData?.data[0]?.totalAtRiskCount);
    } else {
      setTotalNonCompliantData('');
      setTotalAtRiskData('');
    }
  }, [complianceActionsData]);

  useEffect(() => {
    setIsDisabled(false);
    setMyOrgCompletedData(
      myOrgTrainingCompletionData !== undefined
        ? myOrgTrainingCompletionData?.completionDetails?.data
        : 0,
    );
  }, [myOrgTrainingCompletionData]);

  const handleSwitch = () => {
    setMyOrgCompletedData(0);
    if (switchState === switchStateValues.onTime) {
      setTrainingState(switchStateValues.onTime);
      setSwitchState(switchStateValues.overall);
    } else {
      setTrainingState(switchStateValues.overall);
      setSwitchState(switchStateValues.onTime);
    }
  };
  /**
   * handleFilter.
   * This handler update the data as per child component click event
   * @param {string} button
   * @param {string} timeRange
   */
  const handleFilter = (buttom, timeRange) => {
    setActiveFilter(buttom);
    setTimeRangeState(timeRange);
  };

  const handleSelectStatusChange = (event) => {
    if (event.target.value === 'All' || event.target.value.length === 0) {
      setSelectedStatus('');
      setSelectedStatusView('All');
    } else {
      setSelectedStatus(event.target.value);
      setSelectedStatusView(event.target.value);
    }
  };

  useEffect(() => {
    // Prepare dynamic autocomplete configurations
    const configs = [
      {
        label: 'WWID / Team Member',
        options: [],
        getOptionLabel: (option) => option,
        minWidth: '210px',
        mr: 2,
      },
      {
        label: 'Training Name',
        options: [],
        getOptionLabel: (option) => option,
        minWidth: '330px',
        mr: 2,
      },
      // Add more configurations as needed
    ];
    setAutocompleteConfigs(configs);
  }, []);

  useEffect(() => {
    // Prepare dynamic autocomplete configurations
    const complianceConfigs = [
      {
        label: 'WWID / Team Member',
        options: [],
        getOptionLabel: (option) => option,
        minWidth: '210px',
        mr: 2,
      },

      // Add more configurations as needed
    ];
    setcomplianceautocompleteConfigs(complianceConfigs);
  }, []);

  const handlePopupButtonClick = (value, compliant) => {
    setIsCompliantPopup(compliant);
    setPopupStatus('drill');
    setPopUserId(logInUserDetails?.user_id);
    setPopUpName(logInUserDetails?.full_name);
    setOpenPopup(true);
    setShowLargeDataDownloadSuccessMsg(false);
    // Set the desired header value
    setPopupHeader(value);
    setIsCheckboxSelection(true);

    setPopupButtons([
      {
        label: 'Send Reminder',
      },
      {
        label: 'Download Excel',
      },
      // Add more button objects as needed
    ]);
  };

  const columns = [
    {
      field: 'full_name',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Team Member
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.box.gray,
              fontWeight: fontWeightStyles.fw7.fontWeight,
            }}
          >
            WWID
          </Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 1,
      disableColumnMenu: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          overdue: ['complete overdue'].includes(params.value.toLowerCase()),
        });
      },
      renderCell: (params) => {
        return (
          <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
            <span> {params.value}</span>
            <Box sx={{ color: redtheme.palette.box.gray }}>
              <Box sx={{ display: 'inline-flex', gap: '10px' }}>
                {params.row?.user_id}
                {params.row?.leave_of_absence === 'Y' && <LeaveIndicator />}
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'training_title',
      width: screen.resolveStyles({
        mobile: 220,
        tablet: 220,
        desktop: null,
      }),
      //headerName: 'Training Name',
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Training Name</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        const training_title = (
          <Typography sx={{ fontFamily: '"JohnsonText-Regular"' }}>{params.value || ''}</Typography>
        );
        return (
          <ToolTip title={training_title} placement='top' header='Training Name' noOfLetters={50} />
        );
      },
    },
    {
      field: 'transcript_due_date',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Due Date</Typography>
          <Typography
            variant='subtitle2'
            sx={{
              color: redtheme.palette.box.gray,
              fontWeight: fontWeightStyles.fw7.fontWeight,
              fontFamily: '"JohnsonText-Bold"',
            }}
          >
            {popupHeader === 'Overdue Trainings' ? 'Days Overdue' : 'Coming Due'}
          </Typography>
        </Box>
      ),
      disableColumnMenu: true,
      flex: !screen.isDesktop ? 0 : 1,
      renderCell: (params) => {
        if (params.row?.days_overdue !== 0) {
          return (
            <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
              <span> {params.value}</span>
              <Box sx={{ color: redtheme.palette.box.red }}>
                <span>{params?.row?.days_overdue} Days Overdue</span>
              </Box>
            </Box>
          );
        }
        if (params.row?.days_overdue === 0) {
          return (
            <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
              <span> {params.value}</span>
              <Box sx={{ color: redtheme.palette.box.gray }}>
                <span>Coming Due in {Math.abs(params?.row?.days_upcomingdue)} Days</span>
              </Box>
            </Box>
          );
        }
      },
    },
  ];

  const isPopupOpenedForCompliance = () => {
    return popupHeader === 'Total Non-Compliant' || popupHeader === 'Total At Risk';
  };

  const complianceColumns = [
    {
      field: 'full_name',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Team Member
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.box.gray,
              fontWeight: fontWeightStyles.fw7.fontWeight,
            }}
          >
            WWID
          </Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 1,
      disableColumnMenu: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          overdue: ['complete overdue'].includes(params.value.toLowerCase()),
        });
      },
      renderCell: (params) => {
        return (
          <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
            <span> {params.value}</span>
            <Box sx={{ color: redtheme.palette.box.gray }}>
              <span>{params.row?.user_id}</span>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'complianceElement',
      width: screen.resolveStyles({
        mobile: 220,
        tablet: 220,
        desktop: null,
      }),
      //headerName: 'Training Name',
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Compliance Element</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        <Typography sx={{ fontFamily: '"JohnsonText-Regular"' }}>
          {params.row?.complianceElement || ''}
        </Typography>;
      },
    },
    {
      field: 'complianceStatus',
      width: screen.resolveStyles({
        mobile: 220,
        tablet: 220,
        desktop: null,
      }),
      //headerName: 'Training Name',
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Compliance Status</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        const complianceStatus = (
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Regular"',
              color: getComplianceStatusTextColor(params.row?.complianceStatus),
            }}
          >
            {params.row?.complianceStatus || ''}
          </Typography>
        );
        return (
          <ToolTip
            title={complianceStatus}
            placement='top'
            header='Compliance Status'
            noOfLetters={50}
          />
        );
      },
    },
  ];

  const onChangeOfAutoCompleteFilter = (_event, newValue) => {
    if (newValue?.user_id?.length) {
      setSearchSelectedUser(newValue?.user_id);
    } else {
      setSearchSelectedUser('');
    }
  };

  const onInputChangeOfAutoCompleteFilter = (_event, value, reason) => {
    setSearchUsersOptions([]);

    if (value?.length) {
      setSearchTermUser(value);
    } else {
      setSearchTermUser('');
      setSearchUsersOptions([]);
    }
    if (reason === 'clear' || reason === 'reset') {
      setSearchTermUser('');
      setSearchUsersOptions([]);
    }
  };

  useEffect(() => {
    if (
      logInUserDetails.user_id !== undefined &&
      logInUserDetails.user_id !== '' &&
      searchTermUser
    ) {
      const dataPayload = {
        userId: logInUserDetails.user_id,
        loggedInUserId: logInUserDetails.user_id,
        teamMember: searchTermUser,
        apiType: 'SUGGESTION',
      };
      dispatch(SearchMyOrgTrainingsData(dataPayload));
    }
  }, [logInUserDetails.user_id, searchTermUser, selectedDateRange]);

  let myOrgSearchByUserOrWWIDData = useSelector(
    (state) => state.filterMyOrgTrainingsRd?.myOrgTrainingsSearchSuggestions?.data?.records,
  );

  useEffect(() => {
    if (myOrgSearchByUserOrWWIDData !== undefined) {
      setSearchUsersOptions(myOrgSearchByUserOrWWIDData);
    }
  }, [myOrgSearchByUserOrWWIDData]);

  const getlogInUserDetails = (liud) => {
    if (liud?.user_role === userRole.usr2) {
      return `My Team's Compliance`;
    } else {
      return liud?.user_role === userRole.usr3 ? `My Team's Compliance` : '';
    }
  };

  const getTaglineForDonutChart = () => {
    if (activeFilter === myOrgPerform.one) {
      return '*Current year plus the past 3 years';
    } else if (activeFilter === myOrgPerform.two) {
      return '*Current quarter plus the past 4 quarters';
    } else {
      return '*Current month plus the past 11 months';
    }
  };

  /**
   * LEGENDS_TRAINING_COMPLETED_ON_TIME.
   * Tarining completed on Time Legend data & settings
   */
  const LEGENDS_TRAINING_COMPLETED_ON_TIME = {
    data: [{ title: 'Completed On Time', color: redtheme.palette.box.green }],
    settings: {
      spacing: spacing.sp6,
      gap: getDimensionWithUnit(gap.gap4, 'px'),
      paddingTop: getDimensionWithUnit(padding.pad3, 'px'),
      width: getDimensionWithUnit(width.wid150, 'px'),
      circleHeight: getDimensionWithUnit(height.h10, 'px'),
      circleWidth: getDimensionWithUnit(width.wid10, 'px'),
      textTransform: textTransform.cap,
      fontFamily: fontFamily.regular,
      fontWeight: fontWeightStyles.fw4.fontWeight,
      fontSize: fontsize.fs14,
      lineHeight: lineHeight.lH16,
    },
  };
  /**
   * FILTERS_TRAINING_COMPLETED_ON_TIME.
   * Tarining completed on Time filters data & settings
   */
  const FILTERS_TRAINING_COMPLETED_ON_TIME = {
    data: [
      { name: 'Year', id: myOrgPerform.one, testId: 'btn-year' },
      { name: 'Quarter', id: myOrgPerform.two, testId: 'btn-quarterly' },
      { name: 'Month', id: myOrgPerform.three, testId: 'btn-month' },
    ],
    settings: {
      background: redtheme.palette.stack.orange,
      textTransform: textTransform.cap,
      fontFamily: fontFamily.light,
      fontWeight: fontWeightStyles.fw7.fontWeight,
      fontSize: fontsize.fs14,
      lineHeight: lineHeight.lH16,
    },
  };
  /**
   * LEGENDS_OVERALL_COMPLIANCE_PROFILE.
   * Overall Compliance Profile Legend data & settings
   */
  const LEGENDS_OVERALL_COMPLIANCE_PROFILE = {
    data: [
      { title: 'Compliant', color: redtheme.palette.success.main },
      { title: 'At Risk', color: redtheme.palette.warning.main },
      { title: 'Non-Compliant', color: redtheme.palette.error.dark },
    ],
    settings: {
      spacing: spacing.sp2,
      gap: getDimensionWithUnit(gap.gap4, 'px'),
      paddingTop: getDimensionWithUnit(padding.pad3, 'px'),
      width: 'auto',
      circleHeight: getDimensionWithUnit(height.h8, 'px'),
      circleWidth: getDimensionWithUnit(width.wid8, 'px'),
      textTransform: textTransform.cap,
      fontFamily: fontFamily.regular,
      fontWeight: fontWeightStyles.fw4.fontWeight,
      fontSize: fontsize.fs14,
      lineHeight: lineHeight.lH16,
    },
  };

  const graphTileSubHeader = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', margin: '8px' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <ThemeProvider theme={switchTheme}>
                <IOSSwitch
                  id='statusSwitch'
                  onChange={handleSwitch}
                  disabled={isDisabled}
                  data-testid='switch-button'
                />
              </ThemeProvider>
            }
            label={
              <Box sx={{ display: 'flex', flexDirection: 'row', margin: '8px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: fontWeightStyles.fw4.fontWeight,
                      lineHeight: '16px',
                      fontFamily: '"JohnsonText-Regular"',
                    }}
                    data-testid='switch-label'
                  >
                    {`See Completed ${switchState.label}`}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </FormGroup>
        <Box
          sx={{
            display: 'inline',
            cursor: 'default',
            left: '244px',
            top: '35px',
          }}
        >
          <ToolTipWrapper
            title={
              switchState.label === 'Overall'
                ? TapGlobalMessages.myTeamsPerformance.trainingsCompletedOnTimeTooltip
                : TapGlobalMessages.myTeamsPerformance.trainingsCompletedOverAllTooltip
            }
            placement='right'
          >
            <HelpOutlineIcon
              fontSize='medium'
              sx={{
                color: `${RedTheme.palette.common.primaryMainBlue}`,
                marginTop: '5px',
              }}
            ></HelpOutlineIcon>
          </ToolTipWrapper>
        </Box>
      </Box>
    );
  };
  const SubHeader = () => {
    return (
      <Box>
        <Typography> </Typography>
      </Box>
    );
  };

  return (
    <>
      <ShowLoader></ShowLoader>

      <Box
        className='my-organization-performance'
        sx={{
          padding: screen.resolveStyles({
            mobile: '16px',
            tablet: '0 24px 24px 24px',
            desktop: '0 72px 0 72px',
          }),
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          width: '100%',
        }}
        data-testid='my-org-performance-charts'
      >
        <Box sx={{ height: '128px', mb: '10px' }}>
          <Typography
            gutterBottom
            sx={{
              width: '100%',
              fontSize: screen.resolveStyles({
                mobile: '25px',
                tablet: '31px',
                desktop: '39px',
              }),
              lineHeight: '48px',
              fontFamily: '"JohnsonDisplay-Bold"',
              fontWeight: fontWeightStyles.fw7.fontWeight,
              padding: 0,
              margin: 0,
            }}
          >
            {`My Team's Performance`}
          </Typography>
          <Typography
            gutterBottom
            variant='div'
            component='div'
            sx={{
              fontSize: screen.resolveStyles({
                mobile: '14px',
                tablet: '12px',
                desktop: '10px',
              }),
              lineHeight: '16px',
              pb: '15px',
              color: redtheme.palette.box.gray,
              fontWeight: screen.resolveStyles({
                mobile: fontWeightStyles.fw5.fontWeight,
                tablet: fontWeightStyles.fw6.fontWeight,
                desktop: fontWeightStyles.fw7.fontWeight,
              }),
              fontFamily: '"JohnsonText-Regular"',
            }}
            data-testid='lastRefreshDate'
          >
            Last refresh {lastRefreshed()}
          </Typography>
          {logInUserDetails && Object.keys(logInUserDetails).length ? (
            logInUserDetails?.user_role === myOrgPerform.three ? (
              <Tabs
                value={selectedTabValue}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  sx: {
                    height: '3px',
                    borderRadius: '3px',
                    textTransform: 'none',
                  },
                }}
                sx={{
                  minHeight: '49px',
                  '& .Mui-selected': {
                    fontWeight: 'bold',
                    color: `${redtheme.palette.fontColor.black_gray} !important`,
                  },
                }}
              >
                <Tab
                  label='My Direct Reports'
                  data-testid='myDirectReports'
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: fontWeightStyles.fw7.fontWeight,
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                    borderBottom: 3,
                    borderColor: 'divider',
                  }}
                ></Tab>
                <Tab
                  label='My Organisation'
                  data-testid='myOrganizationTab'
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: fontWeightStyles.fw7.fontWeight,
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                    borderBottom: 3,
                    borderColor: 'divider',
                  }}
                ></Tab>
              </Tabs>
            ) : (
              <Tabs
                value={0}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  sx: {
                    height: '3px',
                    borderRadius: '3px',
                    textTransform: 'none',
                  },
                }}
                sx={{
                  minHeight: '49px',
                  '& .Mui-selected': {
                    fontWeight: 'bold',
                    color: `${redtheme.palette.fontColor.black_gray} !important`,
                  },
                }}
              >
                <Tab
                  label='My Direct Reports'
                  data-testid='myDirectReports'
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: fontWeightStyles.fw7.fontWeight,
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                    borderBottom: 3,
                    borderColor: 'divider',
                  }}
                ></Tab>
              </Tabs>
            )
          ) : null}
        </Box>

        {/* <Box sx={{ background: redtheme.palette.background.default, padding: screen.resolveStyles({
              mobile: '16px',
              tablet: '0 24px 24px 24px',
              desktop: '0 72px 0 72px',
            }) }}> */}
        {/* <Box sx={{ display: 'flex', flexDirection: screen.isMobile ? 'column':'row', gap: '48px' }}>
            <Box
              sx={{
                display: 'flex',
                width: screen.resolveStyles({
                  mobile: '100vw', 
                  tablet: '100%',
                  desktop: '100%',
                  }),
                flexWrap: 'wrap',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: '32px',
                marginTop: '24px',
              }}
            >
              
              <GraphTile
                tileHeaderText={`Trainings Completed ${trainingState.label}`}
                myOrgCompletedData={myOrgCompletedData}
                donutLabelText={`COMPLETED </br> ${trainingState?.label?.toUpperCase()}`}
                timeRangeState={timeRangeState}
                donutId={'completionOnTimePieChart'}
                barId={'complianceBarChart'}
                legends = {LEGENDS_TRAINING_COMPLETED_ON_TIME}
                filters = {FILTERS_TRAINING_COMPLETED_ON_TIME}
                onChangeFilterOuter={handleFilter}
                subHeader={graphTileSubHeader()}
                tagline={getTaglineForDonutChart()}
                margin={'0px'}
                color={
                  trainingState === switchStateValues.onTime ? [] : ['#000099', redtheme.palette.info.light]
                }
                barChartColor={trainingState === switchStateValues.onTime ? '' : RedTheme.palette.common.primaryMainBlue}
                isMultiColorBarLine = {false}
                isMultiColorDonut={false}
                isTaglineVisible={true}
                complianceChartData={myOrgTrainingComplianceData}
                handleButtonClick={handleButtonClick}
                activeButton={activeButton}
                DisclaimerToShow={false}
                disclaimerText=''
              /> 
              <Divider
                sx={{
                  marginBottom: '25px',
                  marginTop: '30px',
                  strokeWidth: 1.5,
                  border: '1px solid #E8E6E3',
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '20px',
                    lineHeight: '24px',
                    fotWeight: fontWeightStyles.fw7.fontWeight,
                    mb: '20px',
                  }}
                >
                  Training Actions
                </Typography>

                <ActionButtonWrapper
                    processDataLeft = {trainingOverdueData?.trainingOverdueCount}
                    processDataRight = {trainingUpcomingDueData?.trainingUpcomingDueCount}
                    handlePopupButtonClick={handlePopupButtonClick}
                    handleSelectedDaysChange={handleSelectedDaysChange}
                    selectedDays={selectedDays}
                    toolTipHeaderTextLeft={
                      TapGlobalMessages.myTeamsPerformance.trainingsOverdueTooltipHeader
                    }
                    toolTipTitleTextLeft={
                      TapGlobalMessages.myTeamsPerformance.trainingsOverdueTooltipBody
                    }
                    fontcolorLeft = {redtheme.palette.button.white}
                    toolTipHeaderTextRight={
                      TapGlobalMessages.myTeamsPerformance.trainingsComingDueTooltipHeader
                    }
                    toolTipTitleTextRight={
                      TapGlobalMessages.myTeamsPerformance.trainingsComingDueTooltipBody
                    }
                    fontcolorRight = {redtheme.palette.text.secondary}
                    actionTypeLeft='TRAININGS'
                    tileTypeTextLeft='OVERDUE'
                    actionTypeRight='TRAININGS'
                    tileTypeTextRight='COMING DUE'
                    isComplianceAction = {false}
                  />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: screen.resolveStyles({
                  mobile: '100vw', 
                  tablet: '100%',
                  desktop: '100%',
                  }),
                flexWrap: 'wrap',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: '32px',
                marginTop: '24px',
              }}
              data-testid='my-org-performance-infobox'
            >
              <GraphTile
                tileHeaderText='Overall Compliance Profile'
                myOrgCompletedData={myOrgCompletedData}
                donutLabelText={selectedTabValue === 0 ? 'Overall Compliance </br> profile for my team' : 'Overall Compliance </br> profile for my organization'}
                timeRangeState={timeRangeState}
                legends = {LEGENDS_OVERALL_COMPLIANCE_PROFILE}
                donutId={'completionOnTimePieChart2'}
                barId={'complianceBarChart2'}
                subHeader={SubHeader()}
                tagline={' '}
                margin={'30px'}
                color={['#000099', '#E6F3FA']}
                barChartColor={'#6EBD44'}
                isMultiColorBarLine = {true}
                isTaglineVisible={false}
                isMultiColorDonut={true}
                complianceChartData={myOrgTrainingComplianceData}
                DisclaimerToShow={true}
                disclaimerText={TapGlobalMessages.disclaimertext.myorgdisclaimertext}
              />
              <Divider
                sx={{
                  marginBottom: '25px',
                  marginTop: '30px',
                  strokeWidth: 1.5,
                  border: '1px solid #E8E6E3',
                }}
              />
              <Box sx={{}}>
                <Typography
                  sx={{
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '20px',
                    lineHeight: '24px',
                    fotWeight: fontWeightStyles.fw7.fontWeight,
                    mb: '20px',
                  }}
                >
                  Compliance Actions
                </Typography>

                <ActionButtonWrapper
                    processDataLeft = {totalNonCompliantData}
                    processDataRight = {totalAtRiskData}
                    handlePopupButtonClick={handlePopupButtonClick}
                    handleSelectedDaysChange={handleSelectedDaysChange}
                    selectedDays={selectedDays}
                    toolTipHeaderTextLeft={
                      ''
                    }
                    toolTipTitleTextLeft={
                      <NonCompliantTooltipBody/>
                    }
                    fontcolorLeft = {redtheme.palette.button.white}
                    toolTipHeaderTextRight={
                      ''
                    }
                    toolTipTitleTextRight={
                      <AtRiskTooltipBody/>
                    }
                    fontcolorRight = {redtheme.palette.text.secondary}
                    actionTypeLeft = 'TOTAL'
                    tileTypeTextLeft = 'NON-COMPLIANT'
                    actionTypeRight = 'TOTAL'
                    tileTypeTextRight = 'AT RISK'
                    isComplianceAction = {true}
                  />
              </Box>
            </Box>
          </Box> */}
        <Grid container spacing={6}>
          <Grid
            item
            xs={screen.isMobile ? 12 : 6}
            data-testid='my-org-performance-training-completed'
          >
            <GraphTile
              tileHeaderText={`Trainings Completed ${trainingState.label}`}
              myOrgCompletedData={myOrgCompletedData}
              donutLabelText={`COMPLETED </br> ${trainingState?.label?.toUpperCase()}`}
              timeRangeState={timeRangeState}
              donutId={'completionOnTimePieChart'}
              barId={'complianceBarChart'}
              legends={LEGENDS_TRAINING_COMPLETED_ON_TIME}
              filters={FILTERS_TRAINING_COMPLETED_ON_TIME}
              onChangeFilterOuter={handleFilter}
              subHeader={graphTileSubHeader()}
              tagline={getTaglineForDonutChart()}
              margin={'0px'}
              color={
                trainingState === switchStateValues.onTime
                  ? []
                  : ['#000099', redtheme.palette.info.light]
              }
              barChartColor={
                trainingState === switchStateValues.onTime
                  ? ''
                  : RedTheme.palette.common.primaryMainBlue
              }
              isMultiColorBarLine={false}
              isMultiColorDonut={false}
              isTaglineVisible={true}
              complianceChartData={myOrgTrainingComplianceData}
              handleButtonClick={handleButtonClick}
              activeButton={activeButton}
              DisclaimerToShow={false}
              disclaimerText=''
              downloadExcelObj={{
                excelConfig: {
                  reportType: 'trainingprofile',
                  right: screen.isTablet ? '-33vw' : screen.isMobile ? '-76vw' : '-35vw',
                  top: screen.isTablet ? '-5.5vh' : screen.isMobile ? '-8.3vh' : '-14vh',
                },
              }}
              showLoader={AVCompleteStatus}
              onDownloadClick={handleDownloadExcel}
            />
          </Grid>

          <Grid
            item
            xs={screen.isMobile ? 12 : 6}
            data-testid='my-org-performance-overall-compliance'
          >
            <GraphTile
              tileHeaderText={TapGlobalMessages.overallComplianceProfileTitle}
              myOrgCompletedData={myOrgCompletedData}
              donutLabelText={
                selectedTabValue === 0
                  ? `${TapGlobalMessages.myTeamsComplianceDonutLabelText}`
                  : `${TapGlobalMessages.myOrgComplianceDonutLabelText}`
              }
              timeRangeState={timeRangeState}
              legends={LEGENDS_OVERALL_COMPLIANCE_PROFILE}
              donutId={'completionOnTimePieChart2'}
              barId={'complianceBarChart2'}
              subHeader={SubHeader()}
              tagline={' '}
              margin={'30px'}
              color={['#000099', '#E6F3FA']}
              barChartColor={'#6EBD44'}
              isMultiColorBarLine={true}
              isTaglineVisible={false}
              isMultiColorDonut={true}
              complianceChartData={myOrgTrainingComplianceData}
              DisclaimerToShow={true}
              disclaimerText={TapGlobalMessages.disclaimertext.myorgdisclaimertext}
              downloadExcelObj={{
                excelConfig: {
                  reportType: 'complianceProfile',
                  right: screen.isTablet ? '-33vw' : screen.isMobile ? '-76vw' : '-35vw',
                  top: screen.isTablet ? '-2.4vh' : screen.isMobile ? '-3.3vh' : '-5vh',
                },
              }}
              onDownloadClick={handleDownloadExcel}
            />
          </Grid>

          <Grid item xs={screen.isMobile ? 12 : 6}>
            <Typography
              sx={{
                fontFamily: '"JohnsonDisplay-Bold"',
                fontSize: '20px',
                lineHeight: '24px',
                fotWeight: fontWeightStyles.fw7.fontWeight,
                mb: '20px',
              }}
            >
              Training Actions
            </Typography>

            <ActionButtonWrapper
              processDataLeft={trainingOverdueData?.trainingOverdueCount}
              processDataRight={trainingUpcomingDueData?.trainingUpcomingDueCount}
              handlePopupButtonClick={handlePopupButtonClick}
              handleSelectedDaysChange={handleSelectedDaysChange}
              selectedDays={selectedDays}
              toolTipHeaderTextLeft={
                TapGlobalMessages.myTeamsPerformance.trainingsOverdueTooltipHeader
              }
              toolTipTitleTextLeft={
                TapGlobalMessages.myTeamsPerformance.trainingsOverdueTooltipBody
              }
              fontcolorLeft={redtheme.palette.button.white}
              toolTipHeaderTextRight={
                TapGlobalMessages.myTeamsPerformance.trainingsComingDueTooltipHeader
              }
              toolTipTitleTextRight={
                TapGlobalMessages.myTeamsPerformance.trainingsComingDueTooltipBody
              }
              fontcolorRight={redtheme.palette.text.secondary}
              actionTypeLeft='TRAININGS'
              tileTypeTextLeft='OVERDUE'
              actionTypeRight='TRAININGS'
              tileTypeTextRight='COMING DUE'
              isComplianceAction={false}
            />
            {!screen.isMobile && (
              <Divider
                sx={{
                  marginBottom: '25px',
                  marginTop: '30px',
                  strokeWidth: 1.5,
                  border: '1px solid #E8E6E3',
                }}
              />
            )}
          </Grid>
          <Grid item xs={screen.isMobile ? 12 : 6}>
            <Typography
              sx={{
                fontFamily: '"JohnsonDisplay-Bold"',
                fontSize: '20px',
                lineHeight: '24px',
                fotWeight: fontWeightStyles.fw7.fontWeight,
                mb: '20px',
              }}
            >
              Compliance Actions
            </Typography>

            <ActionButtonWrapper
              processDataLeft={totalNonCompliantData}
              processDataRight={totalAtRiskData}
              handlePopupButtonClick={handlePopupButtonClick}
              handleSelectedDaysChange={handleSelectedDaysChange}
              selectedDays={selectedDays}
              toolTipHeaderTextLeft={''}
              toolTipTitleTextLeft={<NonCompliantTooltipBody />}
              fontcolorLeft={redtheme.palette.button.white}
              toolTipHeaderTextRight={''}
              toolTipTitleTextRight={<AtRiskTooltipBody />}
              fontcolorRight={redtheme.palette.text.secondary}
              actionTypeLeft='TOTAL'
              tileTypeTextLeft='NON-COMPLIANT'
              actionTypeRight='TOTAL'
              tileTypeTextRight='AT RISK'
              isComplianceAction={true}
            />
            <Divider
              sx={{
                marginBottom: '25px',
                marginTop: '30px',
                strokeWidth: 1.5,
                border: '1px solid #E8E6E3',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              component='h4'
              variant='h4'
              className='my-teams-trainings-heading'
              data-testid='my-teams-trainings-table-heading'
              sx={{
                fontSize: screen.resolveStyles({
                  mobile: '20px',
                  tablet: '25px',
                  desktop: '31px',
                }),
                lineHeight: screen.resolveStyles({ mobile: '24px', tablet: '32px' }, 'tablet'),
                fontFamily: '"JohnsonDisplay-Bold"',
                fontWeight: fontWeightStyles.fw7.fontWeight,
                marginTop: '-33px',
              }}
            >
              {logInUserDetails &&
                Object.keys(logInUserDetails).length &&
                getlogInUserDetails(logInUserDetails)}
            </Typography>

            <Box sx={{ width: 1, minHeight: 100 }}>
              {logInUserDetails &&
              Object.keys(logInUserDetails).length &&
              logInUserDetails?.user_role === myOrgPerform.two ? (
                <Box data-testid='managerSingleTeam' className='manager-single-team'>
                  <ManagerSingleTeamTable></ManagerSingleTeamTable>
                </Box>
              ) : (
                <Box data-testid='managerManyTeams' className='manager-many-teams'>
                  <Box></Box>
                  {selectedTabValue === 0 ? (
                    <ManagerSingleTeamTable />
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          marginBottom: '20px',
                          flexDirection: 'row',
                          width: screen.isMobile || screen.isTablet ? '100%' : '80%',
                          flexWrap: screen.isMobile || screen.isTablet ? 'wrap' : {},
                          alignItems: screen.isMobile || screen.isTablet ? 'center' : {},
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: screen.resolveStyles({
                              mobile: '35%',
                              tablet: `${100 / 3}%`,
                              desktop: '55%',
                            }),
                          }}
                        >
                          <Autocomplete
                            options={searchUsersOptions}
                            getOptionLabel={(option) => option.search_value}
                            onInputChange={onInputChangeOfAutoCompleteFilter}
                            onChange={onChangeOfAutoCompleteFilter}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  shrink: true,
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                label={
                                  <Typography
                                    sx={{
                                      fontSize: screen.isTablet ? '20px' : '16px',
                                      lineHeight: '14px',
                                      fontWeight: 500,
                                      fontFamily: '"JohnsonText-Bold"',
                                      color: 'rgba(33, 33, 33, 1)',
                                      width: '728px',
                                      height: screen.isTablet ? '22px' : '16px',
                                      paddingTop: screen.isTablet ? '3px' : NUMBER_ARRAY.zero,
                                    }}
                                  >
                                    WWID / User Name
                                  </Typography>
                                }
                                placeholder='Search'
                                sx={{
                                  borderBottom: '1px solid #81766F',
                                  marginBottom: screen.isTablet && NUMBER_ARRAY.zero,
                                }}
                                variant='standard'
                                margin='normal'
                                data-testid='userAutoComplete'
                              />
                            )}
                            sx={{ mr: 2, width: '100%' }}
                            clearOnBlur
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            width: '2vw',
                            alignItems: 'end',
                          }}
                        >
                          <ToolTipWrapper
                            title={TapGlobalMessages.myTeamsPerformance.singleTeam.searchByMangOrWWIDInfoTooltip()}
                            placement='top'
                            header='How Search Works?'
                          >
                            <HelpOutlineIcon
                              fontSize='medium'
                              sx={{ color: `${RedTheme.palette.common.primaryMainBlue}` }}
                            ></HelpOutlineIcon>
                          </ToolTipWrapper>
                        </Box>
                        <Box sx={{ width: '48vw', fontFamily: '"JohnsonText-Medium"' }}>
                          <FormControl
                            variant='standard'
                            data-testid='status_filter'
                            sx={{
                              width: '95%',
                              mt: 2,
                              fontFamily: '"JohnsonText-Medium"',
                              marginLeft: '20px',
                            }}
                          >
                            <InputLabel
                              variant='standard'
                              id='select-status-label'
                              sx={{
                                fontSize: screen.isTablet ? '20px' : '16px',
                                lineHeight: '14px',
                                fontWeight: 500,
                                fontFamily: '"JohnsonText-Bold"',
                                color: 'rgba(33, 33, 33, 1)',
                                width: '728px',
                                height: screen.isTablet ? '22px' : '16px',
                                paddingTop: screen.isTablet ? '3px' : NUMBER_ARRAY.zero,
                              }}
                              shrink={true}
                            >
                              Status
                            </InputLabel>
                            <Select
                              id='dd-select-status'
                              data-testid='statusInput'
                              IconComponent={DropDownIcon}
                              value={selectedStatusView}
                              onChange={handleSelectStatusChange}
                              sx={{
                                borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                              }}
                              displayEmpty={true}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <Typography
                                      data-testid='statusInputSelect'
                                      sx={{
                                        color: redtheme.palette.fontColor.gray_orange,
                                        fontSize: '16px',
                                        fontWeight: 400,
                                      }}
                                    >
                                      Select
                                    </Typography>
                                  );
                                }
                                return selectedStatusView;
                              }}
                            >
                              <MenuItem value='All'>All</MenuItem>
                              {TapGlobalMessages.myTeamsPerformance.statusOptionsList.map(
                                (status) => (
                                  <MenuItem key={status} value={status}>
                                    {status}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <ManagerManyTeamsTable
                        isFirstLoad={!isLoaded}
                        isFirstLevel={true}
                        userID={logInUserDetails.user_id}
                        userName={logInUserDetails.full_name}
                        selectedStatus={selectedStatus}
                        isSearch={searchSelectedUser !== undefined && searchSelectedUser !== ''}
                        searchForUserID={searchSelectedUser}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Toaster
          onClose={() => setShowLargeDataDownloadSuccessMsg(false)}
          hideDuration={NUMBER_ARRAY.thirtyThousand}
          severity={toasterSeverity}
          show={showLargeDataDownloadSuccessMsg}
          message={largeDataDownloadMsg}
        />
      </Box>

      <GenericPopup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
          setPopUserId('');
        }}
        header={popupHeader}
        columns={isPopupOpenedForCompliance() ? complianceColumns : columns}
        buttons={popupButtons}
        autocompletes={
          isPopupOpenedForCompliance() ? complianceautocompleteConfigs : autocompleteConfigs
        }
        setAutocompleteConfigs={
          isPopupOpenedForCompliance() ? setcomplianceautocompleteConfigs : setAutocompleteConfigs
        }
        popupHeader={popupHeader}
        selectedDays={selectedDays}
        statusMapper={selectedTabValue === 1 ? statusMapper : directReporteeStatusMapper}
        popUserId={popUserId}
        popupStatus={popupStatus}
        popUpName={popUpName}
        isCheckboxSelection={isCheckboxSelection}
        selectedTabValue={selectedTabValue}
        isCompliantPopup={isCompliantPopup}
        isGenericPopupOpen={openPopup}
      />
    </>
  );
};