/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to
 * keep your app working for UI changes and
 * make it independent of network requirements.
 *
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 *
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */
// Actual endpoints


export const POST_USER_DETAILS_URL = () => 'user/profile';
export const POST_TRAINING_STATUS_URL = () => `myPerformance/userTrainingStatus`;
export const POST_MY_ORG_COMPLETION_URL = () => `myTeamPerformance/teamsCompletedStatus`;
export const POST_MY_DIRECT_REPOTEE_COMPLETION_URL = () => `myTeamPerformance/directReportTeamsCompletedStatus`; 
export const POST_MY_ORG_COMPLIANCE_URL = () => `myTeamPerformance/teamsComplianceStatus`;
export const POST_MY_DIRECT_REPORTEE_COMPLIANCE_URL = () => `myTeamPerformance/directReportComplianceStatus`;
/**
* POST_TEAM_PERFORMANCE_TRAINING_OVERDUE_URL.
*/
export const POST_TEAM_PERFORMANCE_TRAINING_OVERDUE_URL = () =>
  `myTeamPerformance/trainingOverDue`;
/**
* POST_TEAM_PERFORMANCE_TRAINING_UPCOMINGDUE_URL.
*/  
export const POST_TEAM_PERFORMANCE_TRAINING_UPCOMINGDUE_URL = () =>
  `myTeamPerformance/trainingUpcomingDue`;
/**
* POST_ORG_PERFORMANCE_NON_COMPLIANT_URL.
*/    

export const POST_TEAM_DIRECT_REPORT_TRAINING_OVERDUE_URL = () =>
  `myTeamPerformance/directReportTrainingOverDue`;
/**
* POST_TEAM_PERFORMANCE_TRAINING_UPCOMINGDUE_URL.
*/  
export const POST_TEAM_DIRECT_REPORT_TRAINING_UP_COMING_DUE_URL = () =>
  `myTeamPerformance/directReportTrainingUpcomingDue`;
/**
* POST_ORG_PERFORMANCE_NON_COMPLIANT_URL.
*/    

export const POST_ORG_PERFORMANCE_NON_COMPLIANT_URL = () =>
  `myTeamPerformance/totalNonCompliant`;
/**
* POST_ORG_PERFORMANCE_AT_RISK_URL.
*/    
export const POST_ORG_PERFORMANCE_AT_RISK_URL = () =>
  `myTeamPerformance/totalAtRisk`;
/**
* POST_MY_ORG_TRAININGS_URL.
*/     

export const POST_DIRECT_REPORTEE_NON_COMPLIANT_URL = () =>
  `directReport/myTeamPerformance/totalNonCompliant`;
/**
* POST_ORG_PERFORMANCE_AT_RISK_URL.
*/    
export const POST_DIRECT_REPORTEE_AT_RISK_URL = () =>
  `directReport/myTeamPerformance/totalAtRisk`;
/**
* POST_MY_ORG_TRAININGS_URL.
*/     

export const POST_MY_ORG_TRAININGS_URL = () => 'myTeamPerformance/teamsTrainingStatus';
export const POST_MY_ORG_COMPLIANCE_TABULAR_DATA_URL = () => 'myTeamPerformance/teamsComplianceTabularData';
export const POST_MY_PERFORMANCE_COMPLIANCE_STATUS_DATA_URL = () => 'myPerformance/userComplianceData';
export const POST_MANAGER_SINGLETEAM_URL = () => 'myTeamPerformance/directReportComplianceTabularData';
export const POST_TEAM_PERFORMANCE_POPUP_DATA = urlStatus => `${urlStatus}/listing`;
export const POST_TEAM_PERFORMANCE_DIRECT_REPORTEE_POPUP_DATA = urlStatus => `${urlStatus}/listing`;
export const POST_TEAM_PERFORMANCE_POPUP_FILTER = actionsType => `${actionsType}/filtersData`
export const POST_DIRECT_REPORTEE_ACTIONS_POPUP_FILTER = actionsType => `${actionsType}/filtersData`
export const POST_DIRECT_REPORTEE_TRAININGS_POPUP_FILTER = actionsType => `${actionsType}/directReportFiltersData`
export const POST_TEAM_PERFORMANCE_POPUP_REMINDER = () => `trainings/reminder`
export const POST_MYPERFORMANCE_SEARCH_TRAINING_BYTITLE_URL = () => 'myPerformance/userTrainings/searchTrainings';
export const GET_TEAM_PERFORMANCE_POPUP_DOWNLOAD = (status, userId, popupStatus, days) => `trainings/${status}/report/${userId}/${popupStatus}?days=${days}`
export const POST_TRAINING_VIEW_INDIVIDUAL_USER_URL = () => "curriculum/individualUser/listing"
export const POST_TRAININGVIEW_TRAININGS_POPUP_URL = () => 'trainingPlanView/getTrainingsInPopUp';
export const GET_TRAININGVIEW_POPUP_REPORT = () => 'trainings/report';

export const POST_TRAININGPLANVIEW_DIRECTREPORTEE_MRGSINGLETEAM_URL = () => 'curriculum/directReportee/listing';
export const POST_ADMINVIEW_COMPLETED_STATUS_URL = () => 'adminView/adminViewCompletedStatus';
export const POST_ADMINVIEW_TRAINING_OVER_DUE_URL = () => 'adminView/trainings/totalOverDue';
export const POST_ADMINVIEW_TRAINING_UPCOMING_DUE_URL = () => 'adminView/trainings/upcomingDue'; 
export const POST_ADMINVIEW_COMPLIANCE_PROFILE_STATUS_URL = () => 'adminView/adminViewComplianceStatus'; 
export const POST_ADMINVIEW_NON_COMPLIANT_URL = () => 'adminView/totalNonCompliant';
export const POST_ADMINVIEW_AT_RISK_URL = () => 'adminView/totalAtRisk'; 
export const POST_ADMIN_VIEW_POPUP_DATA = urlStatus => `${urlStatus}/listing`;
export const POST_ADMINVIEW_POPUP_FILTER = actionsType => `adminView/${actionsType}/filtersData`; 
export const POST_ADMIN_QUICK_FILTER = () => 'adminView/filterdropdown'; 
export const POST_ADMIN_FAVOURITE_FILTER = () => '/adminView/filter/saveFilter';
export const POST_ADMIN_MANAGE_FILTER = () => '/adminView/manageFilters/';

// release/3.0.0 new endpoints added
export const POST_LARGE_DOWNLOAD_REPORT_URL = (reportType, teamType) => `myTeamPerformance/${teamType}/report/${reportType}`;
export const POST_ADMIN_LARGE_DOWNLOAD_REPORT_URL = (reportType) => `adminView/report/${reportType}`;
export const POST_DOWNLOAD_REPORT_URL = () => 'myTeamPerformance/training-compliance/profiles/download';
