import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { DropDownIcon } from 'common/components';
import { RedTheme } from 'common/global';
import PropTypes from 'prop-types';
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';

export const CustomDropDown = ({
  headerText,
  selectedValues,
  handleAdminStatusChange,
  indicator,
  options,
}) => {
  let redtheme = RedTheme;
  const screen = useScreen();

  return (
    <FormControl
      variant='standard'
      data-testid='status_filter'
      sx={{ width: '95%', mt: 2, fontFamily: '"JohnsonText-Medium"' }}
    >
      <InputLabel
        variant='standard'
        id='select-status-label'
        sx={{
          fontSize: screen.isTablet ? '20px' : '14px',
          lineHeight: '12px',
          fontWeight: 500,
          fontFamily: '"JohnsonText-Bold"',
          color: 'rgba(33, 33, 33, 1)',
          width: '728px',
          height: screen.isTablet ? '25px' : '16px',
          paddingTop: screen.isTablet ? '5px' : NUMBER_ARRAY.zero,
        }}
        shrink={true}
      >
        {headerText}
      </InputLabel>
      <Select
        id='dd-select-status'
        data-testid='statusInput'
        IconComponent={DropDownIcon}
        multiple
        value={selectedValues}
        onChange={(e) => handleAdminStatusChange(e, indicator)}
        sx={{ borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}` }}
        displayEmpty={true}
        renderValue={(selected) => {
          if (selected === undefined || selected?.length === 0) {
            return (
              <Typography
                data-testid='statusInputSelect'
                sx={{
                  color: redtheme.palette.fontColor.gray_orange,
                  fontSize: '16px',
                  fontWeight: 400,
                }}
              >
                Select
              </Typography>
            );
          }
          return selected?.map((obj) => obj).join(', ');
        }}
      >
        {options?.map((obj, index) => (
          <MenuItem
            key={index}
            value={
              indicator === 'LegalEntity'
                ? obj.legal_entity
                : indicator === 'Country'
                ? obj.country_name
                : obj.partner_company
            }
          >
            {indicator === 'LegalEntity'
              ? obj.legal_entity
              : indicator === 'Country'
              ? obj.country_name
              : obj.partner_company}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomDropDown.propTypes = {
  headerText: PropTypes.string,
  selectedValues: PropTypes.array,
  handleAdminStatusChange: PropTypes.func,
  indicator: PropTypes.string,
  options: PropTypes.array,
};
