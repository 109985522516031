export const ReportDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case 'POST_REPORT_DOWNLOAD':
      return {
        ...state,
        APIResponse: action.payload,
      };

    case 'INIT_LARGE_REPORT_DOWNLOAD':
      return {
        ...state,
        initiateLargeReportDownload: action.payload,
      };

    case 'API_START':
      if (action.payload === 'FETCH_REPORT_DOWNLOAD') {
        return {
          ...state,
          isFetchingMyTeamsPerformanceReportDownload: true,
        };
      }
      break;

    case 'API_END':
      if (action.payload === 'FETCH_REPORT_DOWNLOAD') {
        return {
          ...state,
          isFetchingMyTeamsPerformanceReportDownload: false,
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export const LargeReportDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case 'POST_LARGE_REPORT_DOWNLOAD':
      return {
        ...state,
        APIResponse: action.payload,
      };

    case 'API_START':
      if (action.payload === 'FETCH_LARGE_REPORT_DOWNLOAD') {
        return {
          ...state,
          isFetchingMyTeamsPerformanceLargeReportDownload: true,
        };
      }
      break;

    case 'API_END':
      if (action.payload === 'FETCH_LARGE_REPORT_DOWNLOAD') {
        return {
          ...state,
          isFetchingMyTeamsPerformanceLargeReportDownload: false,
        };
      }
      break;
    default:
      return state;
  }
  return state;
};
